// @ts-nocheck
import React, {ChangeEvent, useState} from 'react'
import styles from './Results.module.css'
import {Divider, Modal, Typography} from '@mui/material'
import {COLOR_PRIMARY} from 'routes/color-constants'
import {useStyles} from 'features/matchings/Matching.styles'
import {useTranslation} from 'react-i18next'
import {
    Button,
    Checkbox,
    createStyles,
    FormControl,
    FormControlLabel,
    InputLabel,
    MenuItem,
    Select,
    Switch,
    Theme,    
    Table,
    TableCell,
    TableContainer,
    TableRow,
    TextField,
    Box,
} from '@material-ui/core'
import {Head} from 'components/table/Head'
import {Body} from 'components/table/Body'
import {GenericTooltip} from 'components/generic-tooltip'
import CloseIcon from '@material-ui/icons/Close'
import {Legend} from 'components/legend/Legend'
import {customOrder, RolePGDTest, rolePgdTest} from 'modules/users/enums/RoleType'
import {validColors} from 'modules/analysis/enum/ColorEnum'
import {getProjectContainer} from '../../container/project-module'
import {ProjectService} from '../../modules/projects/services/ProjectService'
import { PROJECT_SERVICE_KEY} from '../../modules/projects'
import {ProjectAnalysis} from '../../modules/projects/models/Project'
import {getAuthContainer} from '../../container/auth-modules'
import {AUTH_SERVICE_KEY} from '../../modules/auth'
import {AuthService} from '../../modules/auth/services/AuthService'
import {MutationsPositionService} from '../../modules/mutationsPosition/services/MutationsPositionService'
import {MUTATIONS_POSITION_SERVICE_KEY} from '../../modules/mutationsPosition'
import {getMutationsPositionContainer} from '../../container/mutationsPositions-module'
import {getWordContainer} from '../../container/word-module'
import {WordService} from '../../modules/word/services/WordService'
import {WORD_SERVICE_KEY} from '../../modules/word'
import {WordDTO} from '../../modules/word/models/Word'
import {makeStyles} from '@material-ui/core/styles'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import CancelIcon from '@material-ui/icons/Cancel'
import { FinalStatus } from 'modules/individuals/enums/Status'
import {EMBRYO_SERVICE_KEY} from 'modules/embryos'
import {EmbryoService} from 'modules/embryos/services/EmbryoService'
import {getEmbryoContainer} from 'container/embryo-module'
import CircularProgress from '@mui/material/CircularProgress'
import { Individual } from 'modules/individuals/models/Individual'
import {getIndividualContainer} from 'container/individual-module'
import {IndividualService} from 'modules/individuals/services/IndividualService'
import {INDIVIDUAL_SERVICE_KEY} from 'modules/individuals'
import { Embryo } from 'modules/embryos/models/Embryo'
import { Individuals } from 'modules/individuals/enums/Individuals'
import { commonStyles } from 'common/styles/Styles'
import { statusTitle, finalStatusTitle} from '../../modules/individuals/enums/Status'






export type ResultsProps = {
    project?: ProjectAnalysis
    setProject: (s: ProjectAnalysis) => void
    data: any
    return?: (b: boolean) => void
    currentStep?: number
    individuals: Individual[]
    embryos?: Embryo[]
}

const embryoService = getEmbryoContainer().get<EmbryoService>(EMBRYO_SERVICE_KEY)


const usaStyles = makeStyles((theme: Theme) =>
    createStyles({
        containerAllele: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            backgroundColor: 'white',
            width: '35%',
            height: 'auto',
            padding: '30px',
            minWidth: '400px',
            borderRadius: '8px',
            paddingTop: '21px',
            justifyContent: 'center',
            display: 'flex',
            alignContent: 'center',
            flexDirection: 'column',
        },
        alleleTitle: {
            fontWeight: 'bold',
            fontSize: '15px',
            marginTop: '1.5%',
            alignSelf: 'center',
        },
        alleleDivs: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            width: '100%',
            alignSelf: 'center',
            marginTop: '5.5%',
        },
        alleleCloseIcon: {
            position: 'absolute',
            top: '6px',
            right: '9px',
            cursor: 'pointer',
        },
        formControl: {
            minWidth: 120,
        },
        toggle: {
            width: 68,
            height: 47,
            pading: 0,
            '& .MuiSwitch-thumb': {
                boxSizing: 'border-box',
                width: 30,
                height: 30,
            },
            '& .MuiSwitch-track': {
                borderRadius: 47 / 2,
            },
        },
    })
)

const projectService = getProjectContainer().get<ProjectService>(PROJECT_SERVICE_KEY)
const authService = getAuthContainer().get<AuthService>(AUTH_SERVICE_KEY)
const mutationService = getMutationsPositionContainer().get<MutationsPositionService>(
    MUTATIONS_POSITION_SERVICE_KEY
)
const wordService = getWordContainer().get<WordService>(WORD_SERVICE_KEY)

export function Results(props: ResultsProps): JSX.Element {
    const stylesComon = commonStyles()
    const classes = useStyles({color: COLOR_PRIMARY})
    const {t} = useTranslation()
    const [motherInfo, setMotherInfo] = useState<boolean>(true)
    const [fatherInfo, setFatherInfo] = useState<boolean>(true)
    const [noInfo, setNoInfo] = useState<boolean>(false)
    const [semiInfo, setSemiInfo] = useState<boolean>(false)
    const [afOn, setAfOn] = useState<boolean>(false)
    const [qualityCoverage, setQualityCoverage] = useState<boolean>(false)
    const [alleleModal, setAlleleModal] = useState<boolean>(false)
    const [currentPosition, setCurrentPosition] = useState<number>()
    const [currentAIndex, setCurrentAIndex] = useState<number>()
    const [data2, setData2] = useState<any>(props.data)
    const [project, setProject] = useState<ProjectAnalysis | undefined>(props?.project)
    const [selectedOption, setSelectedOption] = useState('')
    const [mutationChanges, setMutationChanges] = useState<boolean>(false)
    const [saveMutationModal, setSaveMutationModal] = useState<boolean>(false)
    const [changes, setChanges] = useState<boolean>(false)
    const [changesModal, setChangesModal] = useState<boolean>(false)
    const [currentIndividual, setCurrentIndividual] = useState<any>({})
    const [wordsChanges, setWordsChanges] = useState<WordDTO[]>([])
    const [dataLoading, setDataLoading] = useState<Boolean>(false)
    const [individuals, setIndividuals] = useState<Individual[]>(props.individuals)
    const [embryos, setEmbryos] = useState<Embryo[]>(props?.embryos)

    const options = [
        {value: '#e8d863', label: t('yellowM')},
        {value: '#09d6e8', label: t('blueM')},
        {value: '#ed6afa', label: t('pinkM')},
        {value: '#03de6c', label: t('greenM')},
        {value: '#ff7b54', label: t('orangeM')},
        {value: '#ddeafb', label: t('whiteM')},
        {value: '#e4a397', label: t('incongruityM')},
        {value: 'rgb(153, 153, 153)', label: t('lowqualitycoverageM')}

    ]

    const fields: Field<any>[] = [
        {
            sortable: false,
            searchable: false,
            label: '',
            name: 'view',
        },
            {
                sortable: false,
                searchable: false,
                label: t('individual'),
                name: 'individual',
            },
            {
                sortable: false,
                searchable: false,
                label: t('status'),
                name: 'status',
            },
            {
                sortable: false,
                searchable: false,
                label: t('mutation'),
                name: 'mutation',
            },
            {
                sortable: false,
                searchable: false,
                label: t('nonCall%'),
                name: 'nonCall%',
            },
        ]

        const fields2: Field<any>[] = [
            {
                sortable: false,
                searchable: false,
                label: '',
                name: 'view',
            },
                {
                    sortable: false,
                    searchable: false,
                    label: t('sampleName'),
                    name: 'sampleName',
                },
                {
                    sortable: false,
                    searchable: false,
                    label: t('status'),
                    name: 'status',
                },
                {
                    sortable: false,
                    searchable: false,
                    label: t('sex'),
                    name: 'sex',
                },
                {
                    sortable: false,
                    searchable: false,
                    label: t('nonCall%'),
                    name: 'nonCall%',
                },
            ]

    console.log(data2)

    const subtractLight = function (color: any, amount: any) {
        let cc = parseInt(color, 16) - amount
        let c = cc < 0 ? 0 : cc
        //@ts-ignore
        c = c.toString(16)?.length > 1 ? c.toString(16) : `0${c.toString(16)}`
        return c
    }

    const darken = (color: any, amount: any) => {
        color = color.indexOf('#') >= 0 ? color.substring(1, color?.length) : color
        //@ts-ignore
        amount = parseInt((255 * amount) / 100)
        return (color = `#${subtractLight(color?.substring(0, 2), amount)}${subtractLight(
            color.substring(2, 4),
            amount
        )}${subtractLight(color.substring(4, 6), amount)}`)
    }

    const getDataComponentFiltered = (data: any) => {
        switch (true) {
            case data?.individualType === 'mother_info' && !motherInfo:
                return ''
            case data?.individualType === 'father_info' && !fatherInfo:
                return ''
            case data?.individualType === 'no_info' && !noInfo:
                return ''
            case data?.individualType === 'semi_info' && !semiInfo:
                return ''
            case data?.individualType === '':
                return ''
            default:
                return getDataComponent(data, data?.individualType)
        }
    }

    const onPress = (aindex: number, position: number, indexIndividual: number, ind: any) => {
        setAlleleModal(true)
        setCurrentPosition(position)
        setCurrentAIndex(aindex)
        setCurrentIndividual(ind)
    }

    const handleChangeColor = (newColor: string) => {
        let row = data2.resultTableData.filter((r: any) => r.position === currentPosition)

        if (currentAIndex === 1) {
            currentIndividual.a1.color = newColor
        } else {
            currentIndividual.a2.color = newColor
        }

        if (row[0].individualType === 'MutationAdded') {
            setMutationChanges(true)
        } else {
            if (currentAIndex === 1) {
                let wordToPush: WordDTO = {
                    id: currentIndividual.a1.id,
                    word: currentIndividual.a1.word,
                    color: newColor,
                    key: currentIndividual.a1.key,
                }

                let array = wordsChanges ? [...wordsChanges] : []
                array.push(wordToPush)
                setWordsChanges(array)
            } else if (currentAIndex === 2) {
                let wordToPush: WordDTO = {
                    id: currentIndividual.a2.id,
                    word: currentIndividual.a2.word,
                    color: newColor,
                    key: currentIndividual.a2.key,
                }

                let array = wordsChanges ? [...wordsChanges] : []
                array.push(wordToPush)
                setWordsChanges(array)
            }
            setChanges(true)
        }

        setAlleleModal(false)
        // setShowScrollbar(true)
        setSelectedOption('')
    }

    const allowedChars = new Set(['C', 'T', 'G', 'A'])

    const handleInput = (e: any, aindex: number, indexIndividual: number, ind: any, data: any) => {
        const inputText = e.target.innerText

        if (inputText.length === 1 && allowedChars.has(inputText)) {
            e.target.dataset.valid = 'true'
        } else {
            e.target.innerText = '' // Reset to empty if invalid
            e.target.dataset.valid = 'false'
        }
    }

    const handleKeyDown = (e: any, aindex: number, ind: any) => {
        if (e.key === 'Enter') {
            e.preventDefault()
            const inputText = e.target.innerText

            if (e.target.dataset.valid === 'true') {
                updateMutation(aindex, inputText, ind)
                e.target.blur()
            } else {
                e.target.innerText = '' // Reset to empty if invalid
            }
        }
    }

    const updateMutation = (aindex: any, inputText: any, ind: any) => {
        if (aindex === 1) {
            ind.a1.word = inputText
        } else {
            ind.a2.word = inputText
        }

        setMutationChanges(true)
    }

    function filterUniqueRoles(individual: any[]) {
        const uniqueRoles = new Set()
        return individual?.filter((item) => {
            if (!uniqueRoles.has(item.role) || item.role === RolePGDTest.Embryo) {
                uniqueRoles.add(item.role)
                return true
            }
            return false
        })
    }

    function filterUniqueRolesWithoutFemaleAndMale(individual: any[]) {
        const uniqueRoles = new Set()
        return individual?.filter((item) => {
            if (!uniqueRoles.has(item.role) || item.role === RolePGDTest.Embryo) {
                uniqueRoles.add(item.role)
                return true
            }
            return false
        })
    }

    const [selectedMutations, setSelectedMutations] = useState<any[]>([])

    const handleCheckboxChange = (item: any) => {
        setSelectedMutations((prevSelectedItems) => {
            if (prevSelectedItems.find((selectedItem) => selectedItem.position === item.position)) {
                return prevSelectedItems.filter((selectedItem) => selectedItem.position !== item.position)
            } else {
                return [...prevSelectedItems, item]
            }
        })
    }

    //
    const getData = (ind: any, indexIndividual: number, data: any, individualType: string) => {
       
        

        if (ind.role === RolePGDTest.Embryo && isEmbryoHidden2(ind.name) ) {  
             return null}


        //Se encarga de ocultar los que tengan rol diferente a Embryo
       if (ind.role !== RolePGDTest.Embryo && isEmbryoHidden3(ind.role) ) return null
       

        return (
            <div
                style={{
                    backgroundColor:
                        individualType === 'MutationAdded' || individualType === 'MutationMarked'
                            ? '#ffffff'
                            : '',
                    width: qualityCoverage && afOn ? '178px' : (qualityCoverage || afOn)? '118.2px' : '85.5px',
                    position: 'sticky',
                }}>
                <div className={styles.wordContainer2}>
                    <div
                        className={ qualityCoverage && afOn?
                                         styles.subContainerAll 
                                         : qualityCoverage || afOn ?
                                             styles.subContainerQC : styles.subContainer}>
                        <div
                            className={styles.a2Word}
                            key="a2Word"
                            contentEditable={ind.a1.word === ''}
                            onInput={(e) => handleInput(e, data?.position, 2, indexIndividual, ind)}
                            onKeyDown={(e) => handleKeyDown(e, 1, ind)}
                            onClick={() => ind.a1.word && onPress(1, data.position, indexIndividual, ind)}
                            style={{
                                backgroundColor:
                                    individualType === 'MutationAdded'
                                        ? ind.a1.color
                                            ? ind.a1.color
                                            : 'RGB (255, 123, 84)'
                                        : ind.a1.key  && validColors.includes(ind.a1.color)
                                            ? darken(ind.a1.color, 20)
                                            : ind.a1.color
                                                ? ind.a1.color
                                                : '#323232',
                                border: ind.a1.word && `3px solid ${ind.a1.color}`,
                                borderRight: ind.a1.word && `3px solid ${ind.a1.color}`,
                                cursor: 'pointer',
                            }}>
                            {ind.a1.word || '\u200B'}
                        </div>

                        <div
                            key="a1Word"
                            onClick={() => ind.a2.word && onPress(2, data.position, indexIndividual, ind)}
                            className={styles.a2Word}
                            contentEditable={ind.a2.word === ''}
                            onInput={(e) => handleInput(e, data?.position, 2, indexIndividual, ind)}
                            onKeyDown={(e) => handleKeyDown(e, 2, ind)}
                            style={{
                                height: "24.5px",
                                backgroundColor:
                                    individualType === 'MutationAdded'
                                        ? ind.a2.color
                                            ? ind.a2.color
                                            : 'RGB (255, 123, 84)'
                                        : ind.a2.key  && validColors.includes(ind.a2.color)
                                            ? darken(ind.a2.color, 20)
                                            : ind.a2.color
                                                ? ind.a2.color
                                                : '#323232',
                                border: ind.a2.word && `3px solid ${ind.a2.color}`,
                                borderRight:
                                    ind.a2.color !== '#999999'
                                        ? ind.a2.word && `3px solid ${ind.a2.color}`
                                        : '0.5px solid grey',
                                cursor: 'pointer',
                                borderLeft:
                                    ind.a2.key 
                                        ? ind.a2.word && `3px solid ${ind.a2.color}`
                                        : ind.a2.color && '0.5px solid grey',
                            }}>
                            {ind.a2.word || '\u200B'}
                        </div>
                    </div>
                    {qualityCoverage && (
                        <div
                            className={afOn ? styles.subContainerAll : styles.subContainerQC}>
                            <div
                                className={styles.quacoR}
                                style={{
                                height: "24.5px",
                                    backgroundColor: ind.quality > 20 ? 'rgb(182, 197, 204)' : 'rgb(153, 153, 153)',
                                    border: `3px solid ${
                                        ind.quality > 20 ? 'rgb(182, 197, 204)' : 'rgb(153, 153, 153)'
                                    }`,
                                    borderLeft: '1px solid grey',
                                }}>
                                {ind.quality}
                            </div>
                            <div
                                className={styles.quaco}
                                style={{
                                    backgroundColor: ind.coverage > 10 ? 'rgb(182, 197, 204)' : 'rgb(153, 153, 153)',
                                    border: `3px solid ${
                                        ind.coverage > 10 ? 'rgb(182, 197, 204)' : 'rgb(153, 153, 153)'
                                    }`,
                                    borderLeft: '1px solid grey',

                                }}>
                                {ind.coverage}
                            </div>
                        </div>
                    )}
                      {afOn && (
                        <div
                            className={ qualityCoverage ? styles.subContainerAll : styles.subContainerQC}>
                             <div
                                className={styles.alFreq}
                                style={{
                                    backgroundColor:  'rgb(182, 197, 204)',
                                    border: '3px soli rgb(182, 197, 204)',
                                    fontSize: "9px",
                                    borderLeft: '1px solid grey',
                                    

                                }}>
                                {ind.a1Frequency.toFixed(2)}
                            </div>
                            <div
                                className={styles.alFreq}
                                style={{
                                    backgroundColor:  'rgb(182, 197, 204)',
                                    border: '3px soli rgb(182, 197, 204)',
                                    fontSize: "9px",
                                    borderLeft: '1px solid grey',
  
                                    
                                }}>
                                {ind.a2Frequency.toFixed(2)}
                            </div>
                        </div>
                    )}
                </div>
            </div>
        )
    }

    const getDataComponent = (data, individualType) => {
       return (
                <div
                    className={
                        selectedMutations.find((item) => item.position === data.position) ||
                        data.isVariant
                            ? styles.dataContainerCondition
                            : styles.dataContainer
                    }>
                    <Checkbox
                        size="small"
                        className={styles.checkbox}
                        disabled={data.isVariant}
                        checked={
                            data.isVariant||
                            selectedMutations.find((selectedItem) => selectedItem.position === data.position)
                        }
                        onChange={() => handleCheckboxChange(data)}
                    />
                    <div className={styles.dataContainer2}>
                        <div className={styles.dataContainer5}>
                            <div className={styles.chromDiv2}>{data.chromosoma}</div>
                            <div className={styles.dataContainerBorder}/>
                            <div className={styles.posDiv2}>{data.position}</div>
                        </div>
                    </div>
                    {filterUniqueRolesWithoutFemaleAndMale(data.individual)
                        ?.sort((a, b) => customOrder[a.role] - customOrder[b.role])
                        ?.map((ind, indexIndividual) => (  
                            <div
                                className={
                                    ind.role === RolePGDTest.Female
                                        ? isEmbryoHidden3(ind.role) ? styles.fixed1Hiden : styles.fixed1
                                        : ind.role === RolePGDTest.Male
                                            ? qualityCoverage && afOn
                                            ?  isEmbryoHidden3(ind.role)? styles.fixed2WithAllHiden : styles.fixed2WithAll
                                            : qualityCoverage || afOn
                                                ? isEmbryoHidden3(ind.role)? styles.fixed2WithQualityCoverageHiden : styles.fixed2WithQualityCoverage
                                                : isEmbryoHidden3(ind.role)? styles.fixed2Hiden : styles.fixed2
                                            : ind.role !== RolePGDTest.Embryo 
                                                ?isEmbryoHidden3(ind.role)? styles.withoutSpacesHiden : styles.withoutSpaces

                                            : isEmbryoHidden2(ind.name)? styles.withoutSpacesHiden : styles.withoutSpaces
                                }
                                style={{backgroundColor: data.isVariant? 'wheat': 'white'}}


                                key={indexIndividual}>
                                {getData(ind, indexIndividual, data, individualType)}
                            </div>
                        ))}
                </div>
            )
    }

    const handleInfoAnalizedByChangeProject = () => {
        const loggedUser = authService.get()
        let name = loggedUser.firstName + ' ' + loggedUser.lastName
        //getDateTime on string
        let date = new Date().toLocaleString()
        let analizeBy = name + ' ' + date
        if (project) {
            const updatedProject = {
                ...project,
                infoAnalizedBy: project.infoAnalizedBy === '' ? analizeBy : '',
            }
            projectService.update(updatedProject)
            setProject(updatedProject)
            props.setProject(updatedProject)
        }
    }

    const handleChangeInfoValidate = () => {
        const loggedUser = authService.get()
        let name = loggedUser.firstName + ' ' + loggedUser.lastName
        //getDateTime on string
        let date = new Date().toLocaleString()
        let validateBy = name + ' ' + date
        if (project) {
            const updatedProject = {
                ...project,
                infoValidateBy: project.infoValidateBy === '' ? validateBy : '',
            }
            projectService.update(updatedProject)
            setProject(updatedProject)
            props.setProject(updatedProject)
        }
    }

    const handleEmbryoAnalizedByChangeProject = () => {
        const loggedUser = authService.get()
        let name = loggedUser.firstName + ' ' + loggedUser.lastName
        //getDateTime on string
        let date = new Date().toLocaleString()
        let embryoAnalizedBy = name + ' ' + date
        if (project) {
            const updatedProject = {
                ...project,
                embryoAnalizedBy: project.embryoAnalizedBy === '' ? embryoAnalizedBy : '',
            }
            projectService.update(updatedProject)
            setProject(updatedProject)
            props.setProject(updatedProject)
        }
    }

    const handleChangeEmbryoValidate = () => {
        const loggedUser = authService.get()
        let name = loggedUser.firstName + ' ' + loggedUser.lastName
        //getDateTime on string
        let date = new Date().toLocaleString()
        let embryoValidateBy = name + ' ' + date
        if (project) {
            const updatedProject = {
                ...project,
                embryoValidateBy: project.embryoValidateBy === '' ? embryoValidateBy : '',
            }
            projectService.update(updatedProject)
            setProject(updatedProject)
            props.setProject(updatedProject)
        }
    }

    const openSaveMutationModal = () => {
        if (mutationChanges ||changes ){
            mutationChanges && setSaveMutationModal(true)
            changes && setChangesModal(true)
            // markedMutation && setSaveMutationModal(true)
    
        } else props.return(false)

    }

    const closeSaveMutationModal = () => {
        mutationChanges && setSaveMutationModal(false)
    }

    const closeChangesModal = () => {
        changes && setChangesModal(false)
        props.return(false)

    }

    const saveChanges = () => {
        /*TODO
         * Coger words cambiadas y updatearlas en el backend
         * */
        wordsChanges.forEach((word) => {
            wordService.update(word).subscribe(() => {
            })
        })
        closeChangesModal()
        props.return(false)
    }

    const handleChange = (event: ChangeEvent<{ value: unknown }>) => {
        const selectedColor = event.target.value as string
        setSelectedOption(selectedColor)
        handleChangeColor(selectedColor)
    }

    const runParentsSnipetsProcess = () => {
        // setModalPreviewOpened(true)
        setDataLoading(true)
        embryoService.runEmbryoSnipetsProcess(project?.id || '').subscribe((res: any) => {
            let aux = res;
            let auxPreprocessed = res.preProcessedData;
            auxPreprocessed = auxPreprocessed.map((item: any) => ({ ...item, seeEmbryo: true }));
            aux.preProcessedData = auxPreprocessed;
            // setIsLoading(true)
            // setDataLoaded(true)            
            setData2(aux)
            
            setDataLoading(false)
        })
    }
    const styles3 = usaStyles()

    const handleChangeSeeEmbryo = (embryo: any) => {
        let aux = {...data2}
        let auxPreprocessed = data2?.preProcessedData.map((pd: any) =>
            pd.name === embryo.name ? {...pd, seeEmbryo: !pd.seeEmbryo} : pd
        )
        
        
        aux.preProcessedData = auxPreprocessed
        setData2(aux)
    }


    const handleChangeSeeIndividual = (embryo: any) => {
        let aux = {...data2}
        let auxPreprocessed = data2?.preProcessedData.map((pd: any) =>
            pd.name === embryo.fileName ? {...pd, seeEmbryo: !pd.seeEmbryo} : pd
        )
        
        
        aux.preProcessedData = auxPreprocessed
        setData2(aux)
    }

    const isEmbryoHidden = (name: string) => {
        let ret = false
        let embs = data2.preProcessedData.filter((d) => d.name === name)

        if (embs?.length > 0) {
            ret = embs[0].seeEmbryo
        }
        return !ret
    }

    const isEmbryoHidden3 = (role: string) => {
        let ret = false
        let embs = data2.preProcessedData.filter((d) => d.role === role)
        
       
        if (embs?.length > 0) {
            ret = embs[0].seeEmbryo
        }
        return !ret
    }

    const isEmbryoHidden2 = (name: string) => {
        let ret = false
        let embs = data2.preProcessedData.filter((d) => d.sampleName === name)

        if (embs?.length > 0 ) {
            ret = embs[0].seeEmbryo
        }
        return !ret
    }

  

    const colorP = (data: any, aName: string, fStatus: FinalStatus, column: number): string => {

        if (aName === "LR") return "#F2EE81";
        switch (project?.pathology){
            case "X-Linked":
                if (fStatus === FinalStatus.Affected){
                    if  (data.role === RolePGDTest.MaleMother ||
                        data.role === RolePGDTest.FemaleMother ||
                        data.role === RolePGDTest.Female ||
                        (data.role === RolePGDTest.Child && project.childGender === 2) ||
                        (data.role === RolePGDTest.Embryo && data.embryoGender !== "male")
                    ) {
                                return "#ff7b54"; // Rojo claro (Afectado) 
                        } else if (column === 1) {
                            return "#ff7b54";
                        } else return "#96f68a";
                } else if (fStatus === FinalStatus.CarrierFromFather){
                    return column === 1 ?  "#96f68a" :  "#ff7b54";
                }  else if (fStatus === FinalStatus.CarrierFromMother){
                    return column === 2 ?  "#96f68a" :  "#ff7b54";
                } else return "#96f68a";


            default:
                if (fStatus === FinalStatus.Affected)return "#ff7b54"; // Rojo claro (Afectado) 
                else if (fStatus === FinalStatus.CarrierFromFather){
                    return column === 1 ?  "#96f68a" :  "#ff7b54";
                }  else if (fStatus === FinalStatus.CarrierFromMother){
                    return column === 2 ?  "#96f68a" :  "#ff7b54";
                } else return "#96f68a";

        }

    
    };

    const renderHeader = (data) => {
        const role = rolePgdTest()[data?.role]
        const isEmbryo = role === 'embryo'        
        const roleName = isEmbryo ? data.name.replace(/_/g," ") : role


        if (isEmbryoHidden(data.name)) return null

        
        let headerClass =
            data.role === RolePGDTest.Female?
                qualityCoverage && afOn?
                    styles.headerFixed1QualityCoverageAll
                    : qualityCoverage || afOn?
                        styles.headerFixed1QualityCoverageStep1
                        : styles.headerFixed1
                : data.role === RolePGDTest.Male?
                    qualityCoverage && afOn?
                        styles.headerFixed2QualityCoverageAll
                        : qualityCoverage || afOn?
                            styles.headerFixed2QualityCoverageStep1
                            : styles.headerFixed2
                    :qualityCoverage && afOn?
                        styles.headerDivQualityCoverageAll
                        : qualityCoverage || afOn?
                            styles.headerDivQualityCoverageStep1
                            : styles.headerDiv



        return (
            <div className={headerClass}>
                <div className={styles.roleNameDiv}>{roleName}</div>
                <div className={styles.pContainer0}>
                    <div className={styles.pContainer1}>
                        <div className={(qualityCoverage || afOn ) ? styles.subContainerQC : styles.subContainer}>
                           
                            <div className={styles.p1Div} style={{backgroundColor: colorP(data, data.a1ColumnName, data.finalStatus, 1)}}>{data.a1ColumnName}</div>
                            <div className={styles.p2Div} style={{backgroundColor: colorP(data, data.a2ColumnName, data.finalStatus, 2)}}>{data.a2ColumnName}</div>
                        </div>
                        {qualityCoverage && (
                            <div className={qualityCoverage ? styles.subContainerQC : styles.subContainer}>
                                <div className={styles.pQu}>{t('qu')}</div>
                                <div className={styles.pQu}>{t('co')}</div>
                            </div>
                        )}
                         {afOn && (
                            <div className={ afOn ? styles.subContainerQC : styles.subContainer}>
                                <div className={styles.pQu}>{t('1af')}</div>
                                <div className={styles.pQu}>{t('2af')}</div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        )
    }
    return (
        <>
     
                    <Typography
                        style={{
                            fontSize: '17px',
                            fontWeight: 'bold',
                            color: 'rgb(125, 191, 220)',
                            marginBottom: '0.5%',
                        }}
                        align="left">
                        {project.name}
                    </Typography>
                    <Divider/>
                    <div style={{width:"100%", height:"100%"}}>
                        <div className={styles.headerColumn}>
                            <div className={styles.legendDiv}>
                                <Legend/>
                            </div>
                            <div className={styles.divFilters}>
                                <div className={styles.filterColumn}>
                                    <FormControlLabel
                                        className={motherInfo ? styles.toggleOn : styles.toggleOff}
                                        control={
                                            <Switch
                                                className={styles3.toggle}
                                                checked={motherInfo}
                                                onChange={() => setMotherInfo(!motherInfo)}
                                                color="primary"
                                                style={{color: '#FAFAFA'}}
                                            />
                                        }
                                        key={5}
                                        label={'MOTHER INFO'}
                                        style={{marginLeft: '0.2%'}}
                                    />
                                    <FormControlLabel
                                        className={fatherInfo ? styles.toggleOn : styles.toggleOff}
                                        control={
                                            <Switch
                                                className={styles3.toggle}
                                                checked={fatherInfo}
                                                onChange={() => setFatherInfo(!fatherInfo)}
                                                color="primary"
                                                style={{color: '#FAFAFA'}}
                                            />
                                        }
                                        key={5}
                                        label={'FATHER INFO'}
                                        style={{marginLeft: '0.2%'}}
                                    />
                                </div>
                                <div className={styles.filterColumn2}>
                                    <FormControlLabel
                                        className={noInfo ? styles.toggleOn : styles.toggleOff}
                                        control={
                                            <Switch
                                                className={styles3.toggle}
                                                checked={noInfo}
                                                onChange={() => setNoInfo(!noInfo)}
                                                color="primary"
                                                style={{color: '#FAFAFA'}}
                                            />
                                        }
                                        key={5}
                                        label={'NO INFO'}
                                        style={{marginLeft: '0.2%'}}
                                    />
                                    <FormControlLabel
                                        className={semiInfo ? styles.toggleOn : styles.toggleOff}
                                        control={
                                            <Switch
                                                className={styles3.toggle}
                                                checked={semiInfo}
                                                onChange={() => setSemiInfo(!semiInfo)}
                                                color="primary"
                                                style={{color: '#FAFAFA'}}
                                            />
                                        }
                                        key={5}
                                        label={'SEMI INFO'}
                                        style={{marginLeft: '0.2%'}}
                                    />
                                </div>
                                <div className={styles.filterColumn3}>
                                    <FormControlLabel
                                        className={afOn ? styles.toggleOn : styles.toggleOff}
                                        control={
                                            <Switch
                                                className={styles3.toggle}
                                                checked={afOn}
                                                onChange={() => setAfOn(!afOn)}
                                                color="primary"
                                                style={{color: '#FAFAFA'}}
                                            />
                                        }
                                        key={5}
                                        label={'ALLELE FREQUENCY'}
                                        style={{marginLeft: '0.2%'}}
                                    />
                                    <FormControlLabel
                                        className={qualityCoverage ? styles.toggleOn : styles.toggleOff}
                                        control={
                                            <Switch
                                                className={styles3.toggle}
                                                checked={qualityCoverage}
                                                onChange={() => setQualityCoverage(!qualityCoverage)}
                                                color="primary"
                                                style={{color: '#FAFAFA'}}
                                            />
                                        }
                                        key={5}
                                        label={'QUALITY/ COVERAGE'}
                                        style={{marginLeft: '0.2%'}}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className={styles.divTogglesPreview}>
                <TableContainer>
                <Table>
                    <Head
                       fields={fields}
                        styleHeader={{fontWeight: 'bold', color: 'rgb(125, 191, 220)'}}
                    />
                    <Body  fields={fields} />
                        {individuals.map((individual, i) => (
                        <TableRow>
                            <TableCell key="view" className={stylesComon.selectCell}>
                            <div onClick={() => handleChangeSeeIndividual(individual)} >
                                 {  

                                     filterUniqueRoles(data2?.preProcessedData)
                                        .filter(ind => ind.name === individual.fileName)[0].seeEmbryo? (
                                            <CheckCircleIcon className={styles.iconChecked}/>
                                        ) : (
                                            <CancelIcon className={styles.iconNotChecked}/>
                                        )
                                    }
                                    </div>
                            </TableCell>
                            <TableCell key={`individualName${i}`} >{individual.sampleName}</TableCell>

                            <TableCell key={`status${i}`}  className={stylesComon.selectCell}>
                                {statusTitle()[individual.status]}
                            </TableCell>
                            <TableCell key={`variant${i}`} >{individual.mutation}</TableCell>
                            <TableCell key={`nonCall${i}`}>
                            {Math.round(individual.nonCallRate * 100) / 100} %
                               
                            </TableCell>
                        </TableRow>
                        ))}

                </Table>
            </TableContainer>
                                        </div>
                       

            {embryos && embryos.length > 0 && (
                <div className={styles.divTogglesPreview }
                style={{marginTop:"30px"}}>
                <TableContainer>
                <Table>
                    <Head
                       fields={fields2}
                        styleHeader={{fontWeight: 'bold', color: 'rgb(125, 191, 220)'}}
                    />
                    <Body  fields={fields2} />
                        {embryos.map((individual, i) => (

                        <TableRow style={{height: "40px"}}>
                            <TableCell key="view" className={stylesComon.selectCell}>
                            <div onClick={() => handleChangeSeeEmbryo(individual)} >
                                 {  

                                     filterUniqueRoles(data2?.preProcessedData)
                                        .filter(ind => ind.name === individual.name)[0].seeEmbryo? (
                                            <CheckCircleIcon className={styles.iconChecked}/>
                                        ) : (
                                            <CancelIcon className={styles.iconNotChecked}/>
                                        )
                                    }
                                    </div>
                            </TableCell>
                            <TableCell key={`sampleName${i}`} >{individual.sampleName}</TableCell>

                            <TableCell key={`status${i}`}  className={stylesComon.selectCell}>
                                {individual.a1Colors.length > 9 ||  individual.a2Colors.length > 9
                                    ? "Likely Recombinant" 
                                    : finalStatusTitle()[individual.finalStatus] }
                            </TableCell>
                            <TableCell key={`sex${i}`} >{individual.embryoGender}</TableCell>
                            <TableCell key={`nonCall${i}`}>
                                {Math.round(individual.nonCallRate * 100) / 100} %
                            </TableCell>
                        </TableRow>
                        ))}

                </Table>
            </TableContainer>
                                        </div>
            )}
                        </div>
                        <div style={{height:"750px" }}>
                            <div className={styles.overflows}>
                                <div className={styles.container2}>
                                    {filterUniqueRoles(data2?.preProcessedData)
                                        ?.sort((a, b) => customOrder[a.role] - customOrder[b.role])
                                        ?.map((data: any, i: any) => (
                                            <>
                                            
                                                {i === 0 && (
                                                    <div className={styles.container0}>
                                                        {/* {props.currentStep === 2 &&
                                                            <Button
                                                            className={styles.addMutationButton}
                                                            onClick={openModalAddMutation}>{t('addMutation')}</Button>
                                                    
                                                        } */}
                                                        <div className={styles.chromPos}>
                                                            <div className={styles.chromContainer}>{t('chrom')}</div>
                                                            <div className={styles.borderContainer}/>
                                                            <div className={styles.posContainer}>{t('position')}</div>
                                                        </div>
                                                    </div>
                                                )}
                                                {renderHeader(data)}
                                            </>
                                        ))}
                                </div>
                                <div className={styles.scrollContainer}>
                                    {/* FIND */}
                                    {data2?.resultTableData?.map((data: any) => (
                                        <>{getDataComponentFiltered(data)}</>
                                    ))}
                                </div>
                            </div>
                        </div>
                        {props.currentStep === 1 && (
                            <div className={styles.footerContainer}>
                                {project?.infoAnalizedBy !== '' ? (
                                    <div className={styles.analizeByDiv}>
                                        <Typography>{t('analizeBy') + project?.infoAnalizedBy}</Typography>
                                    </div>
                                ) : (
                                    <div className={styles.analizeByDiv}>
                                        <FormControlLabel
                                            className={styles.toggle}
                                            control={
                                                <Switch
                                                    checked={project.infoAnalizedBy !== ''}
                                                    onChange={handleInfoAnalizedByChangeProject}
                                                />
                                            }
                                            label={t('isAnalize')}
                                            style={{marginLeft: '0.2%'}}
                                        />
                                    </div>
                                )}

                                {project?.infoValidateBy !== '' ? (
                                    <div className={styles.validateByDiv}>
                                        <Typography>{t('validateBy') + project?.infoValidateBy}</Typography>
                                    </div>
                                ) : (
                                    <div className={styles.validateByDiv}>
                                        <FormControlLabel
                                            className={styles.toggle}
                                            control={
                                                <Switch
                                                    checked={project.infoValidateBy !== ''}
                                                    onChange={handleChangeInfoValidate}
                                                />
                                            }
                                            label={t('isValidate')}
                                            style={{marginLeft: '0.2%'}}
                                        />
                                    </div>
                                )}

                                <div className={styles.buttonDiv}>
                                    <Button onClick={openSaveMutationModal} className={classes.button}>
                                        {t('ok')}
                                    </Button>
                                </div>
                            </div>
                        )}
                        {props.currentStep === 2 && (
                            <div className={styles.footerContainer}>
                                {project?.embryoAnalizedBy !== '' ? (
                                    <div className={styles.analizeByDiv}>
                                        <Typography>{t('analizeBy') + project?.embryoAnalizedBy}</Typography>
                                    </div>
                                ) : (
                                    <div className={styles.analizeByDiv}>
                                        <FormControlLabel
                                            className={styles.toggle}
                                            control={
                                                <Switch
                                                    checked={project.embryoAnalizedBy !== ''}
                                                    onChange={handleEmbryoAnalizedByChangeProject}
                                                />
                                            }
                                            label={t('isAnalize')}
                                            style={{marginLeft: '0.2%'}}
                                            disabled={props.currentStep !== 2}
                                        />
                                    </div>
                                )}

                                {project?.embryoValidateBy !== '' ? (
                                    <div className={styles.validateByDiv}>
                                        <Typography>{t('validateBy') + project?.embryoValidateBy}</Typography>
                                    </div>
                                ) : (
                                    <div className={styles.validateByDiv}>
                                        <FormControlLabel
                                            className={styles.toggle}
                                            control={
                                                <Switch
                                                    checked={project.embryoValidateBy !== ''}
                                                    onChange={handleChangeEmbryoValidate}
                                                />
                                            }
                                            label={t('isValidate')}
                                            style={{marginLeft: '0.2%'}}
                                            disabled={props.currentStep !== 2}
                                        />
                                    </div>
                                )}

                                <div className={styles.buttonDiv}>
                                    <Button onClick={openSaveMutationModal} className={classes.button}>
                                        {t('ok')}
                                    </Button>
                                </div>
                            </div>
                        )}
          
            <Modal open={alleleModal} onClose={() => setAlleleModal(false)}>
                <div className={styles3.containerAllele}>
                    <div className={styles3.alleleTitle}>{t('chooseAllele')}</div>
                    <div className={styles3.alleleDivs}>
                        <FormControl className={styles3.formControl}>
                            <InputLabel>{t('selectColor')}</InputLabel>
                            <Select value={selectedOption} onChange={handleChange}>
                                {options.map((option) => (
                                    <MenuItem
                                        key={option.value}
                                        value={option.value}
                                        style={{backgroundColor: option.value}}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>
                    <CloseIcon className={styles3.alleleCloseIcon} onClick={() => setAlleleModal(false)}/>
                </div>
            </Modal>
            {/* <Modal open={modalAddMutation}>
                <Box className={styles2.modal}>
                    <form onSubmit={addMutation}>
                        <Box mt={2} textAlign={'justify'}>
                            {t('addMutationModalMessage')}
                        </Box>
                        <Box mt={2} textAlign={'justify'}>
                            <TextField
                                fullWidth
                                variant={'outlined'}
                                id={'mutation'}
                                type={'string'}
                                label={t('mutationLabel')}
                                onChange={(e) => handleMutation('name', e.target.value)}
                            />
                        </Box>
                        <FormActions actions={actionsDelete} message={t('save')}/>
                    </form>
                </Box>
            </Modal> */}

            <Modal
                open={(props.currentStep === 2 || props.currentStep === 1) && saveMutationModal}
                onClose={() => closeSaveMutationModal()}>
                <div className={styles.containerAllele}>
                    <div className={styles.alleleTitle}>{t('mutationSave')}</div>
                    <div className={styles.alleleDivs}>
                        <div className={styles.allele} onClick={() => saveMutationsAndClose()}>
                            {t('yes')}
                        </div>
                        <div className={styles.allele} onClick={() => closeSaveMutationModal()}>
                            {t('no')}
                        </div>
                    </div>
                    <CloseIcon className={styles.alleleCloseIcon} onClick={() => closeSaveMutationModal()}/>
                </div>
            </Modal>

            <Modal open={(props.currentStep === 2 || props.currentStep === 1) && changesModal} onClose={() => closeChangesModal()}>
                <div className={styles.containerAllele}>
                    <div className={styles.alleleTitle}>{t('mutationSave')}</div>
                    <div className={styles.alleleDivs}>
                        <div className={styles.allele} onClick={() => saveChanges()}>
                            {t('yes')}
                        </div>
                        <div className={styles.allele} onClick={() => closeChangesModal()}>
                            {t('no')}
                        </div>
                    </div>
                    <CloseIcon className={styles.alleleCloseIcon} onClick={() => closeChangesModal()}/>
                </div>
            </Modal>
            {dataLoading &&(
                <Modal open={dataLoading}>
                    <div className={styles.modalLoading}>
                        <CircularProgress className={styles.circularProgress}/>
                    </div>
                </Modal>
            )}
        </>
    )
}
