import {Bedfile, BedfileDTO, BedfileRange, File, FileDTO, Panel, PanelFront} from '../models/File'
import {Observable} from 'rxjs'
import {Query} from '../../../common/api/Query'
import {ItemList} from '../../../common/models/ItemList'
import {Container, IInit} from '../../../common/container/Container'
import {IStatusService} from '../../../common/status/StatusService'
import {IFileApi} from '../api/FileApi'
import {STATUS_SERVICE_KEY} from '../../../container/app'

type Props = {
    apiKey: symbol
}

export interface IFileService extends IInit {
    getByID(id: string): Observable<File | undefined>

    getHeaderByID(id: string): Observable<File | undefined>

    getSignatureByID(id: string): Observable<File | undefined>

    getFilteredList(q: Query<File>): Observable<ItemList<File>>

    getBedfiles(q: Query<File>): Observable<ItemList<any>>

    getBedfileByID(id: string): Observable<Bedfile | undefined>

    getPanelsAndBeds(q: Query<File>): Observable<ItemList<File>>

    add(e: FileDTO): Observable<File | undefined>

    addInm(e: FileDTO): Observable<File | undefined>

    update(e: FileDTO): Observable<File | undefined>

    delete(id: string): Observable<boolean>

    deleteBedfile(id: string): Observable<boolean>

    getCurrentScriptByID(id: string): Observable<File | undefined>

    addHeader(e: FileDTO): Observable<File | undefined>

    addSignature(e: FileDTO): Observable<File | undefined>

    uploadPanelFile(e: FileDTO): Observable<File | undefined>

    uploadBedFile(e: FileDTO): Observable<File | undefined>

    updateBedfile(e: BedfileDTO): Observable<Bedfile | undefined>

    getPanels(q: Query<Panel>): Observable<ItemList<Panel>>

    getByIDInmonucompatibility(id: string): Observable<File | undefined>

    getPanelsNames(q: Query<Panel>): Observable<ItemList<PanelFront>>

    addBAI(e: FileDTO): Observable<File | undefined>

    addBIM(e: FileDTO): Observable<File | undefined>

    addBIM2(e: FormData): Promise<File | undefined>

    addBAI2(e: FormData): Promise<File | undefined>



    getBAI(id: string): Observable<File | undefined>

    getBIM(id: string): Observable<File | undefined>

    getBAIModel(id: string): Observable<File | undefined>

    getBIMModel(id: string): Observable<File | undefined>

    getBedfileRange(id: string): Observable<BedfileRange | undefined>
}

export class FileService implements IFileService {
    private readonly _apiKey: symbol
    private _container!: Container
    private _api!: IFileApi
    private _statusService!: IStatusService

    constructor(p: Props) {
        this._apiKey = p.apiKey
    }

    init(c: Container) {
        this._container = c
        this._api = this._container.get<IFileApi>(this._apiKey)
        this._statusService = this._container.get<IStatusService>(STATUS_SERVICE_KEY)
    }

    getByID(id: string): Observable<File | undefined> {
        return this._api.getByID(id)
    }

    getByIDInmonucompatibility(id: string): Observable<File | undefined> {
        return this._api.getByIDInmonucompatibility(id)
    }

    getFilteredList(q: Query<File>): Observable<ItemList<File>> {
        return this._api.getFilteredList(q).pipe()
    }

    getPanels(q: Query<Panel>): Observable<ItemList<Panel>> {
        return this._api.getPanels(q).pipe()
    }

    getPanelsNames(q: Query<Panel>): Observable<ItemList<PanelFront>> {
        return this._api.getPanelsNames(q).pipe()
    }

    getBedfiles(q: Query<File>): Observable<ItemList<any>> {
        return this._api.getBedfiles(q).pipe()
    }

    getBedfileRange(id: string): Observable<BedfileRange | undefined>{
        return this._api.getBedfileRange(id)
    }

    getBedfileByID(id: string): Observable<Bedfile | undefined> {
        return this._api.getBedfileByID(id)
    }

    getPanelsAndBeds(q: Query<File>): Observable<ItemList<File>> {
        return this._api.getPanelsAndBeds(q).pipe()
    }

    add(e: FileDTO): Observable<File | undefined> {
        return this._api.add(e)
    }

    addInm(e: FileDTO): Observable<File | undefined> {
        return this._api.addInm(e)
    }

    delete(id: string): Observable<boolean> {
        return this._api.delete(id)
    }

    deleteBedfile(id: string): Observable<boolean> {
        return this._api.deleteBedfile(id)
    }

    update(e: FileDTO): Observable<File | undefined> {
        return this._api.update(e)
    }

    getCurrentScriptByID(id: string): Observable<File | undefined> {
        return this._api.getCurrentScriptByID(id)
    }

    addHeader(e: FileDTO): Observable<File | undefined> {
        return this._api.addHeader(e)
    }

    addSignature(e: FileDTO): Observable<File | undefined> {
        return this._api.addSignature(e)
    }

    getHeaderByID(id: string): Observable<File | undefined> {
        return this._api.getHeaderByID(id)
    }

    getSignatureByID(id: string): Observable<File | undefined> {
        return this._api.getSignatureByID(id)
    }

    uploadPanelFile(e: FileDTO): Observable<File | undefined> {
        return this._api.uploadPanelFile(e)
    }

    uploadBedFile(e: FileDTO): Observable<File | undefined> {
        return this._api.uploadBedFile(e)
    }

    updateBedfile(e: BedfileDTO): Observable<Bedfile | undefined> {
        return this._api.updateBedfile(e)
    }

    addBAI(e: FileDTO): Observable<File | undefined> {
        return this._api.addBAI(e)
    }

    addBIM(e: FileDTO): Observable<File | undefined> {
        return this._api.addBIM(e)
    }

    addBIM2(e: FormData): Promise<File | undefined> {
        return this._api.addBIM2(e)
    }

    addBAI2(e: FormData): Promise<File | undefined> {
        return this._api.addBAI2(e)
    }

    getBAI(id: string): Observable<File | undefined> {
        return this._api.getBAI(id)
    }

    getBIM(id: string): Observable<File | undefined> {
        return this._api.getBIM(id)
    }

    getBAIModel(id: string): Observable<File | undefined> {
        return this._api.getBAIModel(id)
    }

    getBIMModel(id: string): Observable<File | undefined> {
        return this._api.getBIMModel(id)
    }
}
