import { Container, IInit } from '../../../common/container/Container'
import { Observable } from 'rxjs'
import { Individual, IndividualQuery, Informativity, InformativityQuery } from '../models/Individual'
import { ItemList } from '../../../common/models/ItemList'
import { Query } from '../../../common/api/Query'
import { IndividualDTO } from '../models/Individual'
import { IIndividualApi } from '../api/IndividualApi'
import { IStatusService } from '../../../common/status/StatusService'
import { STATUS_SERVICE_KEY } from '../../../container/app'

type Props = {
  apiKey: symbol
}

export interface IIndividualService extends IInit {
  getByID(id: string): Observable<Individual | undefined>

  getFilteredList(q: Query<IndividualQuery>): Observable<ItemList<Individual>>

  add(e: Individual): Observable<Individual | undefined>

  update(e: IndividualDTO): Observable<Individual | undefined>

  update2(e: IndividualDTO): Observable<Individual | undefined>

  delete(id: string): Observable<boolean>

  getInformativityList(q: Query<InformativityQuery>): Observable<ItemList<Informativity>>

  updateInformativity(e: Informativity): Observable<Informativity | undefined>

  runParentsSnipetsProcess(projectID: string): Observable<boolean>

  updateBamFiles(e: IndividualDTO): Observable<Individual | undefined>
}

export class IndividualService implements IIndividualService {
  private readonly _apiKey: symbol
  private _container!: Container
  private _api!: IIndividualApi
  private _statusService!: IStatusService

  constructor(p: Props) {
    this._apiKey = p.apiKey
  }

  init(c: Container) {
    this._container = c
    this._api = this._container.get<IIndividualApi>(this._apiKey)
    this._statusService = this._container.get<IStatusService>(STATUS_SERVICE_KEY)
  }

  add(e: IndividualDTO): Observable<Individual | undefined> {
    return this._api.add(e)
  }

  delete(id: string): Observable<boolean> {
    return this._api.delete(id)
  }

  getByID(id: string): Observable<Individual | undefined> {
    return this._api.getByID(id)
  }

  getFilteredList(q: Query<IndividualQuery>): Observable<ItemList<Individual>> {
    return this._api.getFilteredList(q)
  }

  update(e: IndividualDTO): Observable<Individual | undefined> {
    return this._api.update(e)
  }

  update2(e: IndividualDTO): Observable<Individual | undefined> {
    return this._api.update2(e)
  }

  getInformativityList(q: Query<InformativityQuery>): Observable<ItemList<Informativity>> {
    return this._api.getInformativityList(q)
  }

  updateInformativity(e: Informativity): Observable<Informativity | undefined> {
    return this._api.updateInformativity(e)
  }

  runParentsSnipetsProcess(projectID: string): Observable<boolean> {
    return this._api.runParentsSnipetsProcess(projectID)
  }

  updateBamFiles(e: IndividualDTO): Observable<Individual | undefined> {
    return this._api.updateBamFiles(e)
  }
}
