import { MenuItem } from './types'
import {
  URL_CLINICS,
  URL_PATIENTS,
  URL_RECEPTIVENESS,
  URL_SCRIPT,
  URL_USERS,
  URL_PGD_TEST, URL_PATIENTS_PGD,
  URL_IMMUNOCOM,
  URL_PGSEC_TEST,
  URL_PATIENTS_PGSEC,
} from '../../routes/routes-constants'
import {
  TITLE_CLINICS,
  TITLE_PATIENTS,
  TITLE_RECEPTIVENESS,
  TITLE_SCRIPT,
  TITLE_SETTINGS,
  TITLE_USERS,
  TITLE_TESTS,
  TITLE_PATIENTS_PGD,
  TITLE_PATIENTS_RECEPTIVENESS,
  TITLE_IMMUNOCOM,
  TITLE_PATIENTS_PGSEC
} from '../../routes/title-constants'
import { COLOR_MENU_PRIMARY } from '../../routes/color-constants'
import { getAuthContainer } from '../../container/auth-modules'
import { IAuthService } from '../../modules/auth/services/AuthService'
import { AUTH_SERVICE_KEY } from '../../modules/auth'
import { Permission } from '../../common/enums/Permissions'
import i18n from '../../i18n/i18n'

const authService = getAuthContainer().get<IAuthService>(AUTH_SERVICE_KEY)

export const sideBarMenuItems = (receptiveness: boolean, pgd : boolean, pgsec: boolean): MenuItem[] => {
  return [
    {
      name: TITLE_CLINICS,
      path: URL_CLINICS,
      image: '/icons/icon-clinics.svg',
      color: COLOR_MENU_PRIMARY,
      userAccess: authService.userCan(Permission.viewClinics),
    },
    {
      name: TITLE_PATIENTS,
      path: '',
      image: '/icons/icon-patients.svg',
      color: COLOR_MENU_PRIMARY,
      userAccess: true, //authService.userCan(Permission.viewPatients),
      items: [
        {
          name: i18n.t(TITLE_PATIENTS_PGD),
          path: URL_PATIENTS_PGD,
          color: COLOR_MENU_PRIMARY,
          //userAccess: (authService.userCan(Permission.uploadResults) && receptiveness),  //TODO define this access,
          userAccess: pgd
        },
        {
          name: i18n.t(TITLE_PATIENTS_RECEPTIVENESS),
          path: URL_PATIENTS,
          color: COLOR_MENU_PRIMARY,
          userAccess: receptiveness //TODO define this access,
          //userAccess: true
        },
        {
          name: i18n.t(TITLE_PATIENTS_PGSEC),
          path: URL_PATIENTS_PGSEC,
          color: COLOR_MENU_PRIMARY,
          userAccess: pgsec,  //TODO define this access,
          //userAccess: true
        }
      ],
    },

    {
      name: TITLE_TESTS,
      path: '',
      image: '/icons/icon-reports.svg', //TODO define icon for tests
      color: COLOR_MENU_PRIMARY,
      //userAccess: (authService.userCan(Permission.uploadResults) && receptiveness),  //TODO define this access,
      userAccess: true,
      items: [
        {
          name: TITLE_PATIENTS_PGD,
          path: URL_PGD_TEST,
          color: COLOR_MENU_PRIMARY,
          //userAccess: (authService.userCan(Permission.uploadResults) && receptiveness),  //TODO define this access,
          userAccess: pgd
        },
        {
          name: i18n.t(TITLE_RECEPTIVENESS),
          path: URL_RECEPTIVENESS,
          color: COLOR_MENU_PRIMARY,
          //userAccess: (authService.userCan(Permission.uploadResults) && receptiveness),  //TODO define this access,
          userAccess: receptiveness
        },
        {
          name: i18n.t(TITLE_IMMUNOCOM),
          path: URL_IMMUNOCOM,
          color: COLOR_MENU_PRIMARY,
          //userAccess: (authService.userCan(Permission.uploadResults) && receptiveness),  //TODO define this access,
          userAccess: pgd
        },
        {
          name: TITLE_PATIENTS_PGSEC,
          path: URL_PGSEC_TEST,
          color: COLOR_MENU_PRIMARY,
          //userAccess: (authService.userCan(Permission.uploadResults) && receptiveness),  //TODO define this access,
          userAccess: pgsec
        },
      ],
    },
  
    {
      name: TITLE_SETTINGS,
      image: '/icons/icon-settings.svg',
      color: COLOR_MENU_PRIMARY,
      userAccess:
        authService.userCan(Permission.editTestTypes) ||
        authService.userCan(Permission.editGeneTypes) ||
        authService.userCan(Permission.editTranslations) ||
        authService.userCan(Permission.editPatientParams) ||
        authService.userCan(Permission.editUsers) ||
        authService.userCan(Permission.changeScript),
      items: [
        {
          name: TITLE_USERS,
          path: URL_USERS,
          userAccess: authService.userCan(Permission.editUsers),
        },
        {
          name: TITLE_SCRIPT,
          path: URL_SCRIPT,
          userAccess: authService.userCan(Permission.changeScript),
        },
      ],
    },
  ]
}
