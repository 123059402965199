export const TITLE_DASHBOARD: string = 'Bioarray'
export const TITLE_TEST_TYPES: string = 'testTypes'
export const TITLE_GENE_TYPES: string = 'geneTypes'
export const TITLE_USERS: string = 'Users'
export const TITLE_PROFILE_USER: string = 'Profile'
export const TITLE_PATIENTS: string = 'Individuals'
export const TITLE_PATIENTS_PGD: string = 'PGD-SEQ'
export const TITLE_PATIENTS_PGSEC: string = 'PG-SEQ Core Panel'
export const TITLE_PATIENTS_RECEPTIVENESS: string = 'Receptiveness'
export const TITLE_CLINICS: string = 'Laboratories'
export const TITLE_REPORTS: string = 'Reports'
export const TITLE_MATCHING: string = 'Matching'
export const TITLE_PARAMETERS: string = 'Parameters'
export const TITLE_TRANSLATIONS: string = 'Translations'
export const TITLE_PATIENT: string = 'Patient'
export const TITLE_CLINIC: string = 'Laboratories'
export const TITLE_TESTS: string = 'Tests'
export const TITLE_SETTINGS: string = 'Settings'
export const TITLE_RECEPTIVENESS: string = 'Receptivity'
export const TITLE_SCRIPT: string = 'Script'
export const TITLE_BEDFILES: string = 'Bedfiles PGD-SEQ'
export const TITLE_BEDFILESPG: string = 'Bedfiles PG-Seq Core Panel '
export const TITLE_MICROBIOTA: string = 'Microbiota'
export const TITLE_FUTURE_KITS: string = 'FutureKits'
export const TITLE_PROYECTS: string = 'Projects'
export const TITLE_IMMUNOCOM: string = 'Inmunocompatibility'
export const TITLE_RESULTS_PGD: string = 'Results PGD'
export const TITLE_RESULTS_PGSEQ: string = 'Results PG-SEQ Core Panel'
