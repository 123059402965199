import { RouteProps } from '../../routes/AppRouter'
import { ProjectsDetail } from '../../features/projects'
import { Title } from '../../components/common/Title'
import { PROJECT_SERVICE_KEY } from 'modules/projects'
import { ProjectService } from 'modules/projects/services/ProjectService'
import { getProjectContainer } from 'container/project-module'
import React, { useEffect, useState } from 'react'
import { Project } from 'modules/projects/models/Project'
import { useParams } from '@reach/router'
import { DetailPGSec } from 'features/projects/DetailPGSec'

const projectService = getProjectContainer().get<ProjectService>(PROJECT_SERVICE_KEY)

export function DetailPg(props: RouteProps) {
  const [project, setProject] = useState<Project>()
  const params = useParams()

  useEffect(() => {
    projectService.getByID(params.id).subscribe((value) => setProject(value))
  }, [params.id])

  return (
    <>
      <Title color={props.color} title={props.title || ''} />
      <DetailPGSec project={project} patientID={params.patientID} />
    </>
  )
}
