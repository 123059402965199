import {Observable} from "rxjs";
import {MutationsPosition, MutationsPositionDTO, MutationsPositionQuery} from "../models/MutationsPosition";
import {ItemList} from "../../../common/models/ItemList";
import {Container, IInit} from "../../../common/container/Container";
import {Query} from "../../../common/api/Query";
import {IMutationsPositionsApi} from "../api/MutationsPositionsApi";
import {IStatusService} from "../../../common/status/StatusService";
import {STATUS_SERVICE_KEY} from "../../../container/app";


type Props = {
    apiKey: symbol
}

export interface IMutationsPositionService extends IInit {
    getByID(id: string): Observable<ItemList<MutationsPosition>>
    getByProjectID(id: string): Observable<ItemList<MutationsPosition>>

    add(e: MutationsPositionDTO): Observable<MutationsPosition | undefined>
    update(e: any): Observable<MutationsPosition | undefined>
    updateVariant(e: any): Observable<MutationsPosition | undefined>

    delete(id: string): Observable<boolean>
    updateMarkedPositions(e: any): Observable<boolean>
    
}

export class MutationsPositionService implements IMutationsPositionService {
    private readonly _apiKey: symbol
    private _container!: Container
    private _api!: IMutationsPositionsApi
    private _statusService!: IStatusService

    constructor(p: Props) {
        this._apiKey = p.apiKey
    }

    init(c: Container) {
        this._container = c
        this._api = this._container.get<IMutationsPositionsApi>(this._apiKey)
        this._statusService = this._container.get<IStatusService>(STATUS_SERVICE_KEY)
    }

    getByID(id: string): Observable<ItemList<MutationsPosition>> {
        return this._api.getByID(id)
    }

    getByProjectID(id: string): Observable<ItemList<MutationsPosition>> {
        return this._api.getByProjectID(id)
    }


    add(e: MutationsPositionDTO): Observable<MutationsPosition | undefined> {
        return this._api.add(e)
    }

    updateVariant(e: any): Observable<MutationsPosition | undefined> {
        return this._api.updateVariant(e)
    }

    update(e: any): Observable<MutationsPosition | undefined> {
        return this._api.update(e)
    }

    delete(id: string): Observable<boolean> {
        return this._api.delete(id)
    }

    updateMarkedPositions(e: any): Observable<boolean> {
        return this._api.updateMarkedPositions(e)
    }
}