import {RouteProps} from '../../routes/AppRouter'
import {Accordion, AccordionDetails, AccordionSummary, Box, Button, FormControl, FormControlLabel, FormHelperText, Grid, InputLabel, MenuItem, Modal, Select, Switch, TextField, Typography,} from '@material-ui/core'
import {useForm} from '../../common/utils/form-generation/useForm'
import {useTranslation} from 'react-i18next'
import {navigate} from '@reach/router'
import {URL_PGSEC_TEST} from '../../routes/routes-constants'
import React, {useEffect, useState} from 'react'
import {v4 as uuidv4} from 'uuid'
import {FormAction, FormActions} from '../../common/utils/form-generation'
import {Query, QueryParam} from '../../common/api/Query'
import {emptyProjectDTO, Project, ProjectDTO} from '../../modules/projects/models/Project'
import {getProjectContainer} from '../../container/project-module'
import {ProjectService} from '../../modules/projects/services/ProjectService'
import {PROJECT_SERVICE_KEY} from '../../modules/projects'
import {ChildGenderPGD, GenderType} from '../../modules/users/enums/GenderType'
import {KeyboardDatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import {getFileContainer} from 'container/file-module'
import {FileService} from 'modules/files/services/FileService'
import {FILE_SERVICE_KEY} from 'modules/files'
import {Bedfile, Panel} from 'modules/files/models/File'
import {PatientService} from "../../modules/patients/services/PatientService";
import {PATIENT_SERVICE_KEY} from "../../modules/patients";
import {getPatientContainer} from "../../container/patient-module";
import {PatientPGD} from "../../modules/patients/models/PatientPGD";
import CircularProgress from "@mui/material/CircularProgress";
import { commonStyles } from 'common/styles/Styles'
import DeleteIcon from '../../assets/table-icons/delete-icon.svg'
import { ExpandMore } from '@material-ui/icons'
import { getMutationsPositionContainer } from 'container/mutationsPositions-module'
import { MUTATIONS_POSITION_SERVICE_KEY } from 'modules/mutationsPosition'
import { MutationsPositionService } from 'modules/mutationsPosition/services/MutationsPositionService'


const projectService = getProjectContainer().get<ProjectService>(PROJECT_SERVICE_KEY)
const fileService = getFileContainer().get<FileService>(FILE_SERVICE_KEY)
const patientService = getPatientContainer().get<PatientService>(PATIENT_SERVICE_KEY)
const mutationService = getMutationsPositionContainer().get<MutationsPositionService>(
    MUTATIONS_POSITION_SERVICE_KEY
)

type Range = {
    chr?: string,
    startSNP?: number,
    endSNP?: number,
    startGen?: number,
    endGen?: number
}

export type FormProps = RouteProps & {
    project: Project | undefined
    patientID: string
}


const Variants =  [
    "SNP",
    "Indel",
    "Large deletion",
    "Largue Duplication",
    "Explansion",
]

type InfoVariant = {
    id?: string,
    description: string,
    type: string,
    chromosome: string,
    position?: number,
    start?: number,
    end?: number,
    isSame?: boolean,
    cis: boolean,
    trans: boolean,
    allGenes: boolean
}


export const FormPg = (props: FormProps) => {
    const {t} = useTranslation()
    const [panels, setPanels] = useState<Panel[]>([])
    const [bedfiles, setBedfiles] = useState<Bedfile[]>([])
    const [geneSelected, setGeneSelected] = useState<string>('')
    const [panelSelected, setPanelSelected] = useState<Panel>()
    const [panelsNames, setPanelsNames] = useState<string[]>([]);
    const [panelsOptions, setPanelsOptions] = useState<Panel[]>([])
    const [herencyPaternSelected, setHerencyPaternSelected] = useState<string>('')
    const [dataPatient, setDataPatient] = useState<PatientPGD>()
    const [showSpinner, setShowSpinner] = useState(false)
    const [genesRange, setGenesRange] = useState<Range>()
    const [motherInformation, setMotherInformation] = useState<InfoVariant[]>([])
    const [fatherInformation, setFatherInformation] = useState<InfoVariant[]>([])
    const [motherInformationOriginal, setMotherInformationOriginal] = useState<InfoVariant[]>([])
    const [fatherInformationOriginal, setFatherInformationOriginal] = useState<InfoVariant[]>([])
    const [variantModal, setVariantModal] = useState<boolean>(false)
    const [sameAsMaternal, setSameAsMatermal] = useState<InfoVariant>()
    const [currentPaternal, setCurrentPaternal] = useState<number>(0)

    const styles = commonStyles()
    

    useEffect (()=> {
        switch (panelSelected?.gene) {
            case 'HBB':
                setGenesRange({
                    chr: "chr11",
                    startSNP: 3226115,
                    endSNP: 7223526,
                    startGen: 5225481,
                    endGen: 5227071
                })
                break
            case 'GJB2':  
                setGenesRange({
                    chr: "chr13",
                    startSNP: 18219522,
                    endSNP: 22180424,
                    startGen: 20188890,
                    endGen: 20192783
                })
                break
            case 'BRCA2':
                setGenesRange({
                    chr: "chr13",
                    startSNP: 30315459,
                    endSNP: 34385617,
                    startGen: 32316419,
                    endGen: 32403359
                })
                break
            case 'BRCA1':
                setGenesRange({
                    chr: "chr17",
                    startSNP: 41064109,
                    endSNP: 45166667,
                    startGen: 43045572,
                    endGen: 43125358
                })
                break
            case 'CFTR':
                setGenesRange({
                    chr: "chr7",
                    startSNP: 115289520,
                    endSNP: 119709438,
                    startGen: 117480084,
                    endGen: 117667118
                })
                break
            case 'DMD':
                setGenesRange({
                    chr: "chrX",
                    startSNP: 29134089,
                    endSNP: 35335001,
                    startGen: 31121822,
                    endGen: 32412219
                })
                break
            case 'FMR1':
                setGenesRange({
                    chr: "chrX",
                    startSNP: 145912571,
                    endSNP: 149943327,
                    startGen: 147912169,
                    endGen: 147948854
                })
                break
            case 'F8':
                setGenesRange({
                    chr: "chrX",
                    startSNP: 152852529,
                    endSNP: 155645542,
                    startGen: 154837540,
                    endGen: 155022562
                })
                break
            
        }

    },[panelSelected])

    useEffect(() => {
        if (!props.patientID) return
        patientService.getByIDPGD(props.patientID).subscribe((res) => {
            setDataPatient(res)
        })
    }, [])

    useEffect(() => {
        if (!dataPatient) return
        if (dataPatient.gender == GenderType.Male) {
            data.nameHim = dataPatient.firstName + " " + dataPatient.lastName
            dataPatient.dob && (data.dobHim = dataPatient.dob)
            data.nifHim = dataPatient.idPatient
            data.nifHer = dataPatient.idPatientPartner
            data.nameHer = dataPatient.firstNamePartner + " " + dataPatient.lastNamePartner
        } else {
            data.nameHer = dataPatient.firstName + " " + dataPatient.lastName
            dataPatient.dob && (data.dobHer = dataPatient.dob)
            data.nifHer = dataPatient.idPatient
            data.nifHim = dataPatient.idPatientPartner
            data.nameHim = dataPatient.firstNamePartner + " " + dataPatient.lastNamePartner
        }
        setShowSpinner(false)
    }, [dataPatient]);


    useEffect(() => {
        if (!geneSelected) return
        fileService
            .getBedfiles(
                new Query({})
            )
            .subscribe((res) => {
                setBedfiles(
                    res.items.filter((bedfile) => {
                        const match = bedfile.fileName.match(
                            /^PGSeq_([a-zA-Z0-9_.-]+)_([a-zA-Z0-9_.-]+)\.(\d+)\.(\w+)$/
                        )
                        return match && (match[1] === geneSelected || match[1] === "MULTI")
                    })
                )
            })
    }, [geneSelected])

    useEffect(() => {
        fileService.getPanels(new Query({})).subscribe((res) => {

            let uniquePanelNames: string[] = []
            res.items.forEach((panel) => {
                if (!uniquePanelNames.includes(panel.gene)) {
                    uniquePanelNames.push(panel.gene)
                }
            })
            setPanels(res.items);
           let filterUniquePanelesNames: string[] = []
           uniquePanelNames.forEach((panel) => {
            if (panel == "BRCA1" || panel == "BRCA2" || panel == "DMD" || panel == "CFTR" || panel == "FMR1" || panel == "F8" || panel == "GJB2" || panel == "HBB") {
                filterUniquePanelesNames.push(panel)
            }
        })
            setPanelsNames(filterUniquePanelesNames);
        });
    }, []);


    useEffect(() => {

        if (!props.project) {
            return
        }
 
        fileService.getBedfileByID(props.project.toDTO().bedfileID).subscribe((res) => {
            res && setBedfiles([res])
        })
       
       
        let filtered = panels.filter((b) => b.id == props.project?.panelID)
      

        if (filtered.length > 0) {
         setPanelSelected(filtered[0])
        }
           
    }, [props.project, panels])



    useEffect(() => {
        if (!props.project) {
            return
        }
        setData(props.project.toDTO())
    }, [props.project])


    const {handleChange, handleSubmit, data, setData, errors} = useForm<ProjectDTO>({
        validations: {
            name: {
                required: {
                    value: true,
                    message: t('projectNameNotValidError'),
                },
            },
        },

        onSubmit: () => {
            function storeCb(value: Project | undefined) {
                if (value) {
                    goToProyects().then()
                }
            }

            if (!props.project) {
                projectService
                    .add({
                        ...data,
                        mutationPositionsID: uuidv4(),
                        firstGene: String(genesRange?.startGen) || '0',
                        lastGene: String(genesRange?.endGen) || '0',
                        firstSNP: String(genesRange?.startSNP) || '0',
                        lastSNP: String(genesRange?.endSNP) || '0',
                        chr: genesRange?.chr || '',
                        id: uuidv4(),
                        pathology: panelSelected?.pathology || "",
                        childGender: ChildGenderPGD.Unknown,
                    })
                    .subscribe((value) => {
                         if (value){
                            motherInformation.map(variant => (
                                mutationService.add({
                                    id: uuidv4(),
                                    projectID: value.mutationPositionsID ,
                                    chromosome: variant.chromosome || panelSelected?.chr || "",
                                    role: 1,
                                    embryo: -1,
                                    a1Color: "",
                                    a1Word: "",
                                    a2Color: "",
                                    a2Word: "",
                                    position: variant.position || 0,
                                    start: variant.start || panelSelected?.start || 0,
                                    end: variant.end || panelSelected?.end || 0,
                                    cis: variant.cis || false,
                                    trans: variant.trans || false,
                                    type: variant.type || "",
                                    paternal: "mother",
                                    description: variant.description ,
                                    allGenes: variant.allGenes
                                })
        
                            ))
        
                            fatherInformation.map(variant => (
                                mutationService.add({
                                    id: uuidv4(),
                                    projectID: value.mutationPositionsID ,
                                    chromosome: variant.chromosome || panelSelected?.chr || "",
                                    role: 1,
                                    embryo: -1,
                                    a1Color: "",
                                    a1Word: "",
                                    a2Color: "",
                                    a2Word: "",
                                    position: variant.position || 0,
                                    start: variant.start || panelSelected?.start || 0,
                                    end: variant.end || panelSelected?.end || 0,
                                    cis: variant.cis || false,
                                    trans: variant.trans || false,
                                    type: variant.type || "",
                                    paternal: "father",
                                    description: variant.description ,
                                    allGenes: variant.allGenes
        
                                })
        
                            ))}
    
                        storeCb(value)})
                return
            }


            projectService
                .update({
                    ...data,
                    firstGene: String(genesRange?.startGen) || '0',
                    lastGene: String(genesRange?.endGen) || '0',
                    firstSNP: String(genesRange?.startSNP) || '0',
                    lastSNP: String(genesRange?.endSNP) || '0',
                    chr: genesRange?.chr || '',
                })
                .subscribe((value) =>{
                    if (value){
                        if (motherInformation.length > motherInformationOriginal.length){
                            let motherInformationAux = motherInformation.filter(item => !motherInformationOriginal.includes(item))
                            motherInformationAux.map(variant => (
                                mutationService.add({
                                    id: uuidv4(),
                                    projectID: value.mutationPositionsID ,
                                    chromosome: variant.chromosome || panelSelected?.chr || "",
                                    role: 1,
                                    embryo: -1,
                                    a1Color: "",
                                    a1Word: "",
                                    a2Color: "",
                                    a2Word: "",
                                    position: variant.position || 0,
                                    start: variant.start || panelSelected?.start || 0,
                                    end: variant.end || panelSelected?.end || 0,
                                    cis: variant.cis || false,
                                    trans: variant.trans || false,
                                    type: variant.type || "",
                                    paternal: "mother",
                                    description: variant.description ,
                                    allGenes: variant.allGenes
                                })
    
                        ))}

                        let motherInformationAux = motherInformation.filter(item => motherInformationOriginal.includes(item))
                        motherInformationAux.map(variant => (
                            mutationService.updateVariant({
                                ...variant,
                                projectID: value.mutationPositionsID ,
                                chromosome: variant.chromosome || panelSelected?.chr || "",
                                position: variant.position || 0,
                                start: variant.start || panelSelected?.start || 0,
                                end: variant.end || panelSelected?.end || 0,
                                cis: variant.cis || false,
                                trans: variant.trans || false,
                                type: variant.type || "",
                                description: variant.description,
                                paternal: "mother",
                                allGenes: variant.allGenes
                            })

                        ))

                        
    
                        if (fatherInformation.length > fatherInformationOriginal.length){
                            let fatherInformationAux = fatherInformation.filter(item => !fatherInformationOriginal.includes(item))
                            fatherInformationAux.map(variant => (
                            mutationService.add({
                                id: uuidv4(),
                                projectID: value.mutationPositionsID ,
                                chromosome: variant.chromosome || panelSelected?.chr || "",
                                role: 1,
                                embryo: -1,
                                a1Color: "",
                                a1Word: "",
                                a2Color: "",
                                a2Word: "",
                                position: variant.position || 0,
                                start: variant.start || panelSelected?.start || 0,
                                end: variant.end || panelSelected?.end || 0,
                                cis: variant.cis || false,
                                trans: variant.trans || false,
                                type: variant.type || "",
                                paternal: "father",
                                description: variant.description ,
                                allGenes: variant.allGenes
    
                            })
    
                        ))}

                        let fatherInformationAux = fatherInformation.filter(item => fatherInformationOriginal.includes(item))
                        fatherInformationAux.map(variant => (
                            
                            mutationService.updateVariant({
                                ...variant,
                                projectID: value.mutationPositionsID ,
                                chromosome: variant.chromosome || panelSelected?.chr || "",
                                position: variant.position || 0,
                                start: variant.start || panelSelected?.start || 0,
                                end: variant.end || panelSelected?.end || 0,
                                cis: variant.cis || false,
                                trans: variant.trans || false,
                                type: variant.type || "",
                                description: variant.description,
                                paternal: "father",
                                allGenes: variant.allGenes
                            })
                        ))
                    }
                    
                    storeCb(value)})
        },

        initialValues: props.project ? props.project.toDTO() : emptyProjectDTO(props.patientID)
    })

    const goToProyects = () => navigate(URL_PGSEC_TEST)

    const actions: FormAction[] = [
        {
            label: t('back'),
            handleAction: () => goToProyects(),
        },
    ]

    const handleChangeSelectedPanel = (panelName: string) => {
        let selected = panels.filter((p) => p.gene == panelName)
        setGeneSelected(selected?.length > 0 ? selected[0].gene : "")

        setPanelsOptions(selected)
        if (selected.length === 1) {
            handleChange('panelID', selected[0].id)
            setHerencyPaternSelected(selected[0].herencyPatern)
        }
        setPanelSelected(selected[0])
        handleChange('panelID', selected[0].id)
    }

    const handleChangeHerencyPatern = (herencyPatern: string) => {
        let panel = panelsOptions.find((panel) => panel.id === herencyPatern)
        if (panel) {
            setHerencyPaternSelected(panel.herencyPatern)
            setPanelSelected(panel)
            handleChange('panelID', panel.id)
        }
    }


        useEffect(() => {
            console.log(data)
            if (!data) {
                return
            }
            mutationService.getByProjectID(data.mutationPositionsID).subscribe(res => {
                let auxMaternal: InfoVariant[] = []
                let auxPaternal: InfoVariant[] = []
               if (res) {res.items.map(variant => {
                    let aux: InfoVariant = {
                        id: variant.id,
                        chromosome: variant.chromosome,
                        type: variant.type,
                        description: variant.description,
                        cis: variant.cis,
                        trans: variant.trans,
                        allGenes: variant.allGenes
                    }
    
                    if (variant.type === Variants[0] ) aux.position = variant.position
                    else {
                        aux.start = variant.start
                        aux.end = variant.end
                    }
                    if (variant.paternal === 'mother') auxMaternal.push(aux)
                    if (variant.paternal === 'father') auxPaternal.push(aux)
                })
            setMotherInformation(auxMaternal)
            setMotherInformationOriginal(auxMaternal)
            setFatherInformation(auxPaternal)
            setFatherInformationOriginal(auxPaternal)
    
                }
            })
        }, [data])

    const closeVariantModal = () => {
        setVariantModal(false)
    }

        const actionvariantModal: FormAction[] = [
            {
                label: t('close'),
                handleAction: closeVariantModal,
            },
        ]

        
            const handleChangeVariantType = (option:string ,data: string, i: number, parents: string )=>{
                // debugger
                let aux = parents === "mother" ? [...motherInformation] : [...fatherInformation]
                let auxPaternal = [...fatherInformation]
                
                switch (option) {
                    case 'description':
                        aux[i].description =data
                        if (motherInformation.length <= fatherInformation.length){ if (auxPaternal[i].isSame){
                            auxPaternal[i].isSame = false
                        }}
                        break
                    case 'type':
                        aux[i].type = data
                        if (motherInformation.length <= fatherInformation.length){ 
                            if (auxPaternal[i].isSame){
                            auxPaternal[i].isSame = false
                        }}
                        break
                    case 'chromosome':
                        aux[i].chromosome = data
                        if (motherInformation.length <= fatherInformation.length){ if (auxPaternal[i].isSame){
                            auxPaternal[i].isSame = false
                        }}
                        break
                    case 'position':
                        aux[i].position = Number(data) 
                        if (motherInformation.length <= fatherInformation.length){ if (auxPaternal[i].isSame){
                            auxPaternal[i].isSame = false
                        }}
                        break
                    case 'start':
                        aux[i].start = Number(data)
                         if (motherInformation.length <= fatherInformation.length){if (auxPaternal[i].isSame){
                            auxPaternal[i].isSame = false
                        }}
                        break
                     case 'end':
                        aux[i].end = Number(data)
                         if (motherInformation.length <= fatherInformation.length){if (auxPaternal[i].isSame){
                            auxPaternal[i].isSame = false
                        }}
                        break
                    case 'cis':
                        aux[i].cis = !aux[i].cis
                        if (aux[i].trans) aux[i].trans = false 
                        if (motherInformation.length <= fatherInformation.length){if (auxPaternal[i].isSame){
                            auxPaternal[i].isSame = false
                        }}
                        break
                    case 'trans':
                        aux[i].trans = !aux[i].trans
                        if (aux[i].cis) aux[i].cis = false 
                        if (motherInformation.length <= fatherInformation.length){if (auxPaternal[i].isSame){
                            auxPaternal[i].isSame = false
                        }}
                        break
                    case 'all':
                        aux[i].allGenes = !aux[i].allGenes
                        if (aux[i].allGenes) {
                            aux[i].start = panelSelected?.start
                            aux[i].end = panelSelected?.end
                        }
                        if (motherInformation.length <= fatherInformation.length){if (auxPaternal[i].isSame){
                            auxPaternal[i].isSame = false
                        }}
                        break
                    }
                    
                    setFatherInformation(auxPaternal)
        
                    parents === "mother" ? setMotherInformation(aux) : setFatherInformation(aux)
        
                
            }
        
            const deleteVariant = (parent: string , index: number) => {
                if (props.project){
                    let aux: InfoVariant
                    aux = parent === "mother" ?   motherInformation[index] : fatherInformation[index]
                    console.log("entra",aux)
                    if (aux.id) mutationService.delete(aux.id)
                }
                parent === "mother" ? (
                    setMotherInformation(motherInformation.filter(varianta => varianta !== motherInformation[index])) ):
                    setFatherInformation(fatherInformation.filter(varianta => varianta !== fatherInformation[index]))
            }
        
            const copyVariant = (data: InfoVariant, i: number) => {
              if (motherInformation.length > 1 &&  !fatherInformation[i].isSame ){
                setCurrentPaternal(i)
                setVariantModal(true)
              } else {
                let fatherAux: InfoVariant[]
                if (!data.isSame) {
                    // data = motherInformation[i]
                    fatherAux = [...fatherInformation]
                    fatherAux[i] = motherInformation[0]
                    fatherAux[i].isSame = !data.isSame
        
                    setFatherInformation(fatherAux)
        
                } else {
                    fatherAux = [...fatherInformation]
                    fatherAux[i] = {
                        description: '',
                        type: 'Type',
                        chromosome: panelSelected?.chr || '',
                        isSame: false,
                        cis: false,
                        trans: false ,
                        allGenes: false
                    }
        
                    setFatherInformation(fatherAux)
                }}
            }
        
            const copyVariantSelected  = ( i: number, toCopy: number) => {
                let fatherAux: InfoVariant[]
                if (!fatherInformation[i].isSame) {
                    // data = motherInformation[i]
                    fatherAux = [...fatherInformation]
                    fatherAux[i] = motherInformation[toCopy]
                    fatherAux[i].isSame = !fatherInformation[i].isSame
                    setFatherInformation(fatherAux)
        
                } 
                setVariantModal(false)
        
            }
            
        
            const handleGeneticVariants = (individual: string) =>{
                let newVariant: InfoVariant= {
                    description: '',
                    type: 'Type',
                    chromosome: panelSelected?.chr || '',
                    isSame: false,  
                    cis: false,
                    trans: false ,
                    allGenes: false
        
                }
                if (individual === "mother") setMotherInformation([...motherInformation, newVariant])
                else setFatherInformation([...fatherInformation, newVariant])
        
            }
        
            
        const variantTypeForm = (currentVariant: InfoVariant, variantPosition: number, parent: string) => {
    
            switch (currentVariant.type) {
                case Variants[0]:
    
                    let prevSnp =  variantPosition > 0 ?
                            parent === 'mother' ? motherInformation[variantPosition-1].type : fatherInformation[variantPosition-1].type 
                            : ""
                    return (
                        <Grid>
                        <Box style={{
                            color:"rgba(125,191,220,1)", 
                            fontSize:"larger",
                            textAlign: "left", 
                            marginBottom: "10px",
                            marginTop: "20px",
    
    
                            }}>
                                Position of interest
                        </Box>
                        <Grid item style={{display: "flex", justifyContent: "flex-start"}}>
                            
                            <Grid item xs={3} >
                                <TextField 
                                style={{marginRight: "10px"}}
                            id={'chromosome'}
    
                                    variant={'outlined'}
                                    // error={errors['variatnDescription'] !== undefined}
                                    type={'string'}
                                    value={(currentVariant && currentVariant.chromosome)}
                                    label={t('chrom')}
                                    onChange={(event) =>
                                        handleChangeVariantType('chromosome', event.target.value as string, variantPosition, parent)
                                    }
                                    // helperText={errors['variatnDescription']}
                                />
                            </Grid>
                            <Grid item xs={8}>
                                <TextField 
                                    fullWidth
                                    variant={'outlined'}
                                    // error={errors['variatnDescription'] !== undefined}
                                    type={'string'}
                                    value={(currentVariant && currentVariant.position) || ''}
                                    label={t('position')}
                                    onChange={(event) =>
                                        handleChangeVariantType('position', event.target.value as string, variantPosition, parent)
                                    }
                                    // helperText={errors['variatnDescription']}
                                />
                            </Grid>
                            
                        </Grid>
                        {
                            variantPosition === 1 && (
                            <Grid style={{display: "flex", width: "50px"}}>
                                
                                <FormControlLabel
                                    className={styles.toggle2}
                                    control={<Switch/>}
                                    label={'cis'}
                                    style={{ marginLeft: '1%' }}
                                    checked={currentVariant.cis === true}
                                    onChange={()=>handleChangeVariantType('cis', "" , variantPosition, parent)}
                                />
                                    <FormControlLabel
                                    className={styles.toggle2}
                                    control={<Switch/>}
                                    label={'trans'}
                                    style={{ marginLeft: '1%' }}
                                    checked={currentVariant.trans === true}
                                    onChange={()=>handleChangeVariantType('trans', "" , variantPosition, parent)}
    
    
                                />
                            </Grid>
                        )}
                        </Grid>
                    )
                    // De momento el unico diferente sera SNP
                    default:
                        return (
                            <Grid>
                            <Box style={{
                                color:"rgba(125,191,220,1)", 
                                fontSize:"larger",
                                textAlign: "left", 
                                marginBottom: "10px",
                                marginTop: "20px"
        
                                }}>
                                    Position of interest
                            </Box>
                            <Grid item xs= {11}style={{display: "flex", justifyContent: "flex-start"}}>
                                
                                <Grid item xs={2} >
                                    <TextField 
                                    style={{marginRight: "10px"}}
                                id={'chrom'}
        
                                        variant={'outlined'}
                                        // error={errors['variatnDescription'] !== undefined}
                                        type={'string'}
                                        value={(currentVariant.chromosome)}
                                        label={t('chrom')}
                                        onChange={(event) =>
                                            handleChangeVariantType('chromosome', event.target.value as string, variantPosition, parent)
                                        }
                                        // helperText={errors['variatnDescription']}
                                    />
                                </Grid>
                                <Grid item xs={5}>
                                    <TextField 
                                        fullWidth
                                        variant={'outlined'}
                                        // error={errors['variatnDescription'] !== undefined}
                                        type={'string'}
                                        value={(currentVariant && currentVariant.start) || ''}
                                        label={t('Start')}
                                        onChange={(event) =>
                                            handleChangeVariantType('start', event.target.value as string, variantPosition, parent)
                                        }
                                        // helperText={errors['variatnDescription']}
                                    />
                                </Grid>
                                <Grid item xs={5} style={{marginLeft:"5px"}}>
                                    <TextField 
                                        fullWidth
                                        variant={'outlined'}
                                        // error={errors['variatnDescription'] !== undefined}
                                        type={'string'}
                                        value={(currentVariant && currentVariant.end) || ''}
                                        label={t('End')}
                                        onChange={(event) =>
                                            handleChangeVariantType('end', event.target.value as string, variantPosition, parent)
                                        }
                                        // helperText={errors['variatnDescription']}
                                    />
                                </Grid>
                            </Grid>
                            { variantPosition === 1 && (
                                                    <Grid style={{display: "flex", width: "50px"}}>
                                                        
                                                        <FormControlLabel
                                                            className={styles.toggle2}
                                                            control={<Switch/>}
                                                            label={'cis'}
                                                            style={{ marginLeft: '1%' }}
                                                            checked={currentVariant.cis === true}
                                                            onChange={()=>handleChangeVariantType('cis', "" , variantPosition, parent)}
                                                        />
                                                         <FormControlLabel
                                                            className={styles.toggle2}
                                                            control={<Switch/>}
                                                            label={'trans'}
                                                            style={{ marginLeft: '1%' }}
                                                            checked={currentVariant.trans === true}
                                                            onChange={()=>handleChangeVariantType('trans', "" , variantPosition, parent)}
                            
                            
                                                        />
                                                    </Grid>
                                                )}
                             <Grid style={{ width: "100px"}}>
                                                        
                                                        <FormControlLabel
                                                            className={styles.toggle3}
                                                            control={<Switch/>}
                                                            label={'select all genes'}
                                                            style={{ marginLeft: '1%' }}
                                                            checked={currentVariant.allGenes}
                                                            onChange={()=>handleChangeVariantType('all', "" , variantPosition, parent)}
                                                        />
                                                    </Grid>
                            </Grid>
                        )
            }
        }

    return !showSpinner ? (
        <form onSubmit={handleSubmit}>
            <Grid container spacing={2} style={{marginBottom: "1%"}}>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        variant={'outlined'}
                        error={errors['name'] !== undefined}
                        id={'name'}
                        type={'string'}
                        onChange={(event) => handleChange('name', event.target.value)}
                        value={(data && data.name) || ''}
                        label={t('projectName')}
                        helperText={errors['name']}
                    />
                </Grid>

                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        variant={'outlined'}
                        error={errors['nameHer'] !== undefined}
                        id={'nameHer'}
                        type={'string'}
                        onChange={(event) => handleChange('nameHer', event.target.value)}
                        value={(data && data.nameHer) || ''}
                        label={t('nameHer')}
                        helperText={errors['nameHer']}
                    />
                </Grid>
                <Grid item xs={12}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                            style={{width: '100%'}}
                            id="dobHer"
                            autoOk
                            inputVariant={'outlined'}
                            format={t('dateFormat')}
                            value={new Date(data.dobHer) || null}
                            onChange={(newDate) => setData(Object.assign({...data}, {dobHer: newDate}))}
                            required={false}
                            size={'small'}
                            label={t('dobHer')}
                        />
                    </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        variant={'outlined'}
                        error={errors['nifHer'] !== undefined}
                        id={'nifHer'}
                        type={'string'}
                        onChange={(event) => handleChange('nifHer', event.target.value)}
                        value={(data && data.nifHer) || ''}
                        label={t('idPatient')}
                        helperText={errors['nifHer']}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        variant={'outlined'}
                        error={errors['nameHim'] !== undefined}
                        id={'nameHim'}
                        type={'string'}
                        onChange={(event) => handleChange('nameHim', event.target.value)}
                        value={(data && data.nameHim) || ''}
                        label={t('nameHim')}
                        helperText={errors['nameHim']}
                    />
                </Grid>

                <Grid item xs={12}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                            style={{width: '100%'}}
                            id="dobHim"
                            autoOk
                            inputVariant={'outlined'}
                            format={t('dateFormat')}
                            value={data.dobHim || null}
                            onChange={(newDate) => setData(Object.assign({...data}, {dobHim: newDate}))}
                            required={false}
                            size={'small'}
                            label={t('dobHim')}
                        />
                    </MuiPickersUtilsProvider>
                </Grid>
                <Grid container xs={12} justify='space-around'>
                    <Grid container xs={6} spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            variant={'outlined'}
                            error={errors['firstGene'] !== undefined}
                            id={'firstGene'}
                                type={'number'}
                            onChange={(event) => setGenesRange({...genesRange, startGen: Number(event.target.value)})}
                            value={(genesRange && genesRange.startGen) || ''}
                            label={t('firstGene')}
                            helperText={errors['firstGene']}
                            inputProps={{
                                min: genesRange?.startSNP,
                                max: genesRange?.endGen
                            }}
                        />
                    </Grid>
                    </Grid>
                    <Grid container xs={6} spacing={2} >
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            variant={'outlined'}
                            error={errors['lastGene'] !== undefined}
                            id={'lastGene'}
                            type={'number'}
                            onChange={(event) => setGenesRange({...genesRange, endGen: Number(event.target.value)})}
                            value={(genesRange && genesRange.endGen) || ''}
                            label={t('lastGene')}
                            helperText={errors['lastGene']}
                            inputProps={{
                                min: genesRange?.startGen,
                                max: genesRange?.endSNP
                            }}
                        />
                    </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <FormControl fullWidth variant="outlined" error={errors['panelID'] !== undefined}>
                        <InputLabel id="inherit-label">{t('panel')}</InputLabel>
                        <Select
                            labelId="panel"
                            id="panelID"
                            fullWidth
                            style={{textAlign: 'left'}}
                            value={ (panelSelected && panelSelected.gene) || (data && data.panelID)||''}
                            onChange={(event) => handleChangeSelectedPanel(event.target.value as string)}
                            label={t('panel')}>
                            {panelsNames.map((panel) => (
                                <MenuItem value={panel}>{panel}</MenuItem>
                            ))}
                        </Select>
                        <FormHelperText>{errors['panelID']}</FormHelperText>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        variant={'outlined'}
                        error={errors['nifHim'] !== undefined}
                        id={'nifHim'}
                        type={'string'}
                        onChange={(event) => handleChange('nifHim', event.target.value)}
                        value={(data && data.nifHim) || ''}
                        label={t('idPatient')}
                        helperText={errors['nifHim']}
                    />
                </Grid>
                {panelsOptions.length > 1 && (
                    <Grid item xs={12}>
                        <FormControl fullWidth variant="outlined" error={errors['panelID'] !== undefined}>
                            <InputLabel id="inherit-label">{t('herencyPatern')}</InputLabel>
                            <Select
                                labelId="herencyPatern"
                                id="herencyPatern"
                                fullWidth
                                style={{textAlign: 'left'}}
                                value={(data && data.panelID) || ''}
                                onChange={(event) => handleChangeHerencyPatern(event.target.value as string)}
                                label={t('herencyPatern')}>
                                {panelsOptions.map((panel) => (
                                    <MenuItem value={panel.id}>{panel.herencyPatern}</MenuItem>
                                ))}
                            </Select>
                            <FormHelperText>{errors['panelID']}</FormHelperText>
                        </FormControl>
                    </Grid>
                )}
                {panelsOptions.length == 1 && (
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            variant={'outlined'}
                            error={errors['panelID'] !== undefined}
                            id={'herencyPatern'}
                            type={'string'}
                            value={(herencyPaternSelected)}
                            label={t('herencyPatern')}
                            disabled={true}
                            helperText={errors['panelID']}
                        />
                    </Grid>
                )
                }
                <Grid item xs={12}>
                    <FormControl fullWidth variant="outlined" error={errors['bedfileID'] !== undefined}>
                        <InputLabel id="inherit-label">{t('bedfile')}</InputLabel>
                        <Select
                            labelId="bedfileID"
                            id="bedfileID"
                            fullWidth
                            style={{textAlign: 'left'}}
                            value={(data && data.bedfileID) || ''}
                            onChange={(event) =>
                                handleChange('bedfileID', event.target.value as string)
                            }
                            label={t('panel')}>
                            {bedfiles.map((item) => (
                                <MenuItem value={item.id}>{item.name}</MenuItem>
                            ))}
                        </Select>
                        <FormHelperText>{errors['bedfileID']}</FormHelperText>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        variant={'outlined'}
                        error={errors['pathology'] !== undefined}
                        id={'pathology'}
                        type={'string'}
                        value={(panelSelected && panelSelected.pathology) || ''}
                        label={t('herencyPatern')}
                        helperText={errors['pathology']}
                        inputProps={{
                            readOnly: true,
                            disabled: true,
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                            style={{width: '100%'}}
                            id="receptionDate"
                            autoOk
                            inputVariant={'outlined'}
                            format={t('dateFormat')}
                            value={data.receptionDate || null}
                            onChange={(newDate) =>
                                setData(Object.assign({...data}, {receptionDate: newDate}))
                            }
                            required={false}
                            size={'small'}
                            label={t('receptionDate')}
                        />
                    </MuiPickersUtilsProvider>
                </Grid>
            </Grid>
          

            { panelSelected && ( 
            <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMore/>} // Icono para expandir el acordeón
                            aria-controls="panel1-content"
                            id="panel1-header"
                        >
                            <Typography
                                style={{
                                    color:"rgba(125,191,220,1)", 
                                    fontWeight:"bold", 
                                    fontSize:"large", 
                                    textAlign: "left", 
                                    marginBottom: "10px"
                                    }}>
                                    {t('geneticInformation').toUpperCase()}

                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          
 

            <Grid>
                <Grid item xs={12} style={{
                display: "flex",
                
                }}>
                    <Grid item xs={6} style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "start",

                     }}>
                        
                        {motherInformation.length > 0 ? (
                            motherInformation.map((inf, i) => {
                              return (
                                <Grid 
                                    style={{
                                    width: "100%",
                                    marginBottom: '20px'
                                    
                                 }}>
                                    <Box style={{
                                        color:"rgba(125,191,220,1)", 
                                        fontWeight:"bold", 
                                        fontSize:"larger", 
                                        textAlign: "left", 
                                        marginBottom: "10px",

                                     }}>
                                            Maternal Variant {motherInformation.length > 1 &&  ` ${i+1}`}
                                    </Box>
                                    <Grid item xs={11} style={{display: 'flex', flexDirection: 'row'}} >
                                            <TextField
                                                
                                                fullWidth
                                                variant={'outlined'}
                                                // error={errors['variatnDescription'] !== undefined}
                                                id={`variantDescription${i}`}
                                                type={'string'}
                                                value={(inf && inf.description) || ''}
                                                label={t('variantDescription')}
                                                onChange={(event) =>
                                                    handleChangeVariantType('description', event.target.value as string, i, "mother")
                                                }
                                                // helperText={errors['variatnDescription']}
                                            />
                                            {/* <Box>delete boton</Box> */}
                                        <Grid xs={2}>
                                            <Button onClick={()=>{deleteVariant('mother', i)}}>                                              
                                                <img src={DeleteIcon} alt="" />
                                            </Button>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={4} style={{marginTop: '10px'}}>
                                    <FormControl fullWidth variant="outlined">
                                        <Select
                                            id={`typeVariant${i}`}
                                            fullWidth
                                            renderValue={() => inf.type}
                                            style={{textAlign: 'left'}}
                                            value={ (inf&& inf.type )|| "Type"}
                                            onChange={(event) => handleChangeVariantType('type',event.target.value as string, i, "mother")}
                                           >
                                            {Variants.map((v) => (
                                                <MenuItem value={v}>{v}</MenuItem>

                                            ))}
                                        </Select>
                                        </FormControl>
                                        {/* {motherInformation[i].type === Variants.largeDeletion && (
                                           <FormControlLabel control={<Switch defaultChecked />} label="Select all genes" />
                                        )} */}
                                    </Grid>
                                    {inf.type !== "Type" && variantTypeForm(inf, i, "mother")}
                                </Grid>
                            )}) 
                        ): (
                            <Box style={{
                                color:"rgba(125,191,220,1)", 
                                fontWeight:"bold", 
                                fontSize:"larger", 
                                textAlign: "left", 
                                marginBottom: "10px",
                             }}>
                                    Maternal Variant
                            </Box>
                        )}
                        <Box style={{
                            display: "flex",
                            alignItems: "start",
                            borderTop:" 1px solid rgba(125,191,220,1) ",
                            width: "558px",
                            marginRight: "20px"
                         }}>
                            <Button onClick={() => handleGeneticVariants("mother")}  style={{
                                color: "white",
                                marginTop: "10px",
                                fontSize:"smaller",
                                marginLeft: "10px",
                                backgroundColor: "rgba(125,191,220,1)",
                                width: "110px"
                             }}>
                                {t('addNewVariant').toUpperCase()}
                            </Button>
                        </Box>
                    </Grid>

                
                    <Grid item xs={6} style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "start",

                     }}>
                        
                        {fatherInformation.length > 0 ? (
                            fatherInformation.map((inf, i) => {
                              return (
                                <Grid 
                                    style={{
                                    width: "100%",
                                    marginBottom: '20px'
                                 }}>
                                    <Box style={{
                                        color:"rgba(125,191,220,1)", 
                                        fontWeight:"bold", 
                                        fontSize:"larger", 
                                        textAlign: "left", 
                                        marginBottom: "10px",

                                     }}>
                                            Paternal Variant {fatherInformation.length > 1 &&  ` ${i+1}`}
                                    </Box>
                                    <Grid item xs={11} style={{display: 'flex', flexDirection: 'row'}}>
                                        <TextField
                                            
                                            fullWidth
                                            variant={'outlined'}
                                            // error={errors['variatnDescription'] !== undefined}
                                            id={`variantDescription${i}`}
                                            type={'string'}
                                            value={(inf && inf.description) || ''}
                                            label={t('variantDescription')}
                                            onChange={(event) => handleChangeVariantType('description',event.target.value as string, i, "father")}
                                            
                                            // helperText={errors['variatnDescription']}
                                        />
                                        <Grid xs={2}>
                                            <Button onClick={()=>{deleteVariant('father', i)}}>
                                                <img src={DeleteIcon} alt="" />
                                            </Button>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={8} style={{display:'flex', flexDirection:'row', marginTop: '10px'}}>
                                    <FormControl fullWidth variant="outlined">
                                        <Select
                                            id={`typeVariant${i}`}
                                            fullWidth
                                            renderValue={() => inf.type}
                                            style={{textAlign: 'left'}}
                                            value={ (inf&& inf.type )|| "Type"}
                                            onChange={(event) => handleChangeVariantType('type',event.target.value as string, i, "father")}
                                           >
                                            {Variants.map((v) => (
                                                <MenuItem value={v}>{v}</MenuItem>

                                            ))}
                                        </Select>
                                        
                                        </FormControl>
                                        {/* <div style={{width: "27%", display: "flex", justifyContent: "space-between"}}> */}
                                          <FormControlLabel
                                                    className={styles.toggle2}
                                                    control={<Switch/>}
                                                    label={'Same as maternal variant'}
                                                    style={{ marginLeft: '1%' }}
                                                    checked={inf.isSame == true}
                                                    onChange={() => copyVariant(inf, i)}
                                                    disabled={motherInformation[i]? false : true}
                                                  />
                                            
                                        {/* </div> */}
                                    </Grid>
                                    {inf.type !== "Type" &&variantTypeForm(inf, i, "father")}
                                </Grid>
                            )}) 
                        ): (
                            <Box style={{
                                color:"rgba(125,191,220,1)", 
                                fontWeight:"bold", 
                                fontSize:"larger", 
                                textAlign: "left", 
                                marginBottom: "10px",
                             }}>
                                    Paternal Variant
                            </Box>
                        )}
                        <Box style={{
                            display: "flex",
                            alignItems: "start",
                            borderTop:" 1px solid rgba(125,191,220,1) ",
                            width: "568px"

                         }}>
                            <Button onClick={() => handleGeneticVariants("father")}  style={{
                                color: "white",
                                marginTop: "10px",
                                fontSize:"smaller",
                                marginLeft: "10px",
                                backgroundColor: "rgba(125,191,220,1)",
                                width: "110px",

                             }} 
                            >
                                {t('addNewVariant').toUpperCase()}
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
            </AccordionDetails>
            </Accordion>)}
            <FormActions actions={actions}/>
            <Modal open={variantModal}
                    onClose={closeVariantModal}>
                    <Box className={styles.modal} style={{paddingBottom: "30px"}}>
                       
                                <Box mt={2} textAlign={'justify'}>
                                    {t('select maternal variant')}
                                </Box>
                                <FormControl fullWidth variant="outlined">
                                    {/* <InputLabel id="inherit-label">{t('')}</InputLabel> */}
                                    <Select
                                        labelId="childGender"
                                        id="maternalVariant"
                                        fullWidth
                                        style={{textAlign: 'left'}}
                                        value={sameAsMaternal?.description }
                                        onChange={(event) => {
                                            setSameAsMatermal(motherInformation[event.target.value as number])
                                           copyVariantSelected(currentPaternal, event.target.value as number)}}
                                        >
                                        {motherInformation.map((val) => {
                                               return <MenuItem value={motherInformation.indexOf(val)}>{val.description}</MenuItem>
                                            })}
                                    </Select>
                                    
                                </FormControl>
                            
                       
                    </Box>
                </Modal>
        </form>
                
    ) : (
        <Box>
            <CircularProgress/>
        </Box>
    )
}
