import { Panel } from "modules/files/models/File"

// File: report.ts
export enum GenderType {
    Unknown = 0,
    Male ,
    Female 
 
}

export enum RolePGDTest {

    FemaleFather = 0,
    Female,
    Male,
    Child,
    FemaleMother,
    MaleMother ,
    MaleFather,
    Unknown,
    Embryo 

}

export enum StatusIndividual {

    NonCarrier = 0,
    Carrier,
    Affected,
    Null  
 
}
export enum FinalStatusIndividual{
    NonCarrierFinal = 0,
    CarrierFromMother,
    CarrierFromFather,
    AffectedFinal,
    NullFinal 
}

export enum EmbryoGender {
    Female = 0,
    Male,
    Unknown
}


export type Status = {
    RolePGDTest: RolePGDTest,
    StatusIndividual: FinalStatusIndividual,
    Mutation: string, 
}


export type Embryo = {
    SampleName: string,
    FinalStatus: FinalStatusIndividual,
    ColorA1: string,
    ColorA2: string,
    Sex: EmbryoGender,}


export interface ReportDTO {
    Name: string
    Birthdate: Date
    NamePartner: string
    BirthdatePartner: Date
    Gender : GenderType
    GenderPartner:GenderType
    PatientId: string
    PatientIdPartner: string
    SampleType: string
    SampleTypePartner: string
    SampleDate: Date
    SampleDatePartner: Date
    ReportDate: Date
    TotalSNP: number
    Region: string
    Chromosome: string
    FirstGene: string
    LastGene: string
    FirstGenePGD: number
    LastGenePGD: number
    FirstGenePGDPartner: number
    LastGenePGDPartner: number
    PanelFirst: number
    PanelLast: number
    TotalSNPPartner: number
    ValidSNP: number
    ValidSNPPartner: number
    KeySnp: string
    HerencyPatern: string
    Status: Status[]
    Embryos:Embryo[]
    TotalAnalyzedSNP: number
    TotalAnalyzedSNPINFO: number
    Panel: Panel
}


export class Report {
    private _Name: string
    private _Birthdate: Date
    private _NamePartner: string
    private _BirthdatePartner: Date
    private _Gender: GenderType
    private _GenderPartner: GenderType
    private _PatientId: string
    private _PatientIdPartner: string
    private _SampleType: string
    private _SampleTypePartner: string
    private _SampleDate: Date
    private _SampleDatePartner: Date
    private _ReportDate: Date
    private _TotalSNP: number
    private _Region: string
    private _Chromosome: string
    private _FirstGene: string
    private _LastGene: string
    private _FirstGenePGD: number
    private _LastGenePGD: number
    private _FirstGenePGDPartner: number
    private _LastGenePGDPartner: number
    private _PanelFirst: number
    private _PanelLast: number
    private _TotalSNPPartner: number
    private _ValidSNP: number
    private _ValidSNPPartner: number
    private _KeySnp: string
    private _HerencyPatern: string
    private _Status: Status[]
    private _Embryos: Embryo[]
    private _TotalAnalyzedSNP: number
    private _TotalAnalyzedSNPINFO: number
    private _Panel: Panel

    constructor(p: ReportDTO) {
        this._Name = p.Name
        this._Birthdate = p.Birthdate
        this._NamePartner = p.NamePartner
        this._BirthdatePartner = p.BirthdatePartner
        this._Gender = p.Gender 
        this._GenderPartner = p.GenderPartner
        this._PatientId = p.PatientId
        this._PatientIdPartner = p.PatientIdPartner
        this._SampleType = p.SampleType
        this._SampleTypePartner = p.SampleTypePartner
        this._SampleDate = p.SampleDate
        this._SampleDatePartner = p.SampleDatePartner
        this._ReportDate = p.ReportDate
        this._TotalSNP = p.TotalSNP
        this._Region = p.Region
        this._Chromosome = p.Chromosome
        this._FirstGene = p.FirstGene
        this._LastGene = p.LastGene
        this._FirstGenePGD = p.FirstGenePGD
        this._LastGenePGD = p.LastGenePGD
        this._FirstGenePGDPartner = p.FirstGenePGDPartner
        this._LastGenePGDPartner = p.LastGenePGDPartner
        this._PanelFirst = p.PanelFirst
        this._PanelLast = p.PanelLast
        this._TotalSNPPartner = p.TotalSNPPartner
        this._ValidSNP = p.ValidSNP
        this._ValidSNPPartner = p.ValidSNPPartner
        this._KeySnp = p.KeySnp
        this._HerencyPatern = p.HerencyPatern
        this._Status = p.Status
        this._Embryos = p.Embryos
        this._TotalAnalyzedSNP = p.TotalAnalyzedSNP
        this._TotalAnalyzedSNPINFO = p.TotalAnalyzedSNPINFO
        this._Panel = p.Panel
}

    get Name(): string {
        return this._Name
    }

    set Name(value: string) {
        this._Name = value
    }

    get Birthdate(): Date {
        return this._Birthdate
    }

    set Birthdate(value: Date) {
        this._Birthdate = value
    }

    get NamePartner(): string {
        return this._NamePartner
    }

    set NamePartner(value: string) {
        this._NamePartner = value
    }

    get BirthdatePartner(): Date {
        return this._BirthdatePartner
    }

    set BirthdatePartner(value: Date) {
        this._BirthdatePartner = value
    }

    get Gender(): GenderType {
        return this._Gender
    }
    set Gender(value: GenderType) {
        this._Gender = value
    }

    get GenderPartner(): GenderType{
        return this._GenderPartner;
    }
    set GenderPartner(value: GenderType) {
        this._GenderPartner = value;
    }

    get PatientId(): string {
        return this._PatientId;
    }
    set PatientId(value: string) {
        this._PatientId = value;
    }

    get PatientIdPartner(): string {
        return this._PatientIdPartner;
    }
    set PatientIdPartner(value: string) {
        this._PatientIdPartner = value;
    }

    get SampleType(): string {
        return this._SampleType;
    }
    set SampleType(value: string) {
        this._SampleType = value;
    }

    get SampleTypePartner(): string {
        return this._SampleTypePartner;
    }
    set SampleTypePartner(value: string) {
        this._SampleTypePartner = value;
    }

    get SampleDate(): Date {
        return this._SampleDate;
    }
    set SampleDate(value: Date) {
        this._SampleDate = value;
    }

    get SampleDatePartner(): Date {
        return this._SampleDatePartner;
    }
    set SampleDatePartner(value: Date) {
        this._SampleDatePartner = value;
    }

    get ReportDate(): Date {
        return this._ReportDate;
    }
    set ReportDate(value: Date) {
        this._ReportDate = value;
    }

    get TotalSNP(): number {
        return this._TotalSNP;
    }
    set TotalSNP(value: number) {
        this._TotalSNP = value;
    }

    get Region(): string {
        return this._Region;
    }
    set Region(value: string) {
        this._Region = value;
    }

    get Chromosome(): string {
        return this._Chromosome;
    }
    set Chromosome(value: string) {
        this._Chromosome = value;
    }

    get FirstGene(): string {
        return this._FirstGene;
    }
    set FirstGene(value: string) {
        this._FirstGene = value;
    }

    get LastGene(): string {
        return this._LastGene;
    }
    set LastGene(value: string) {
        this._LastGene = value;
    }

    get FirstGenePGD(): number {
        return this._FirstGenePGD;
    }
    set FirstGenePGD(value: number) {
        this._FirstGenePGD = value;
    }

    get LastGenePGD(): number {
        return this._LastGenePGD;
    }
    set LastGenePGD(value: number) {
        this._LastGenePGD = value;
    }

    get FirstGenePGDPartner(): number {
        return this._FirstGenePGDPartner;
    }
    set FirstGenePGDPartner(value: number) {
        this._FirstGenePGDPartner = value;
    }

    get LastGenePGDPartner(): number {
        return this._LastGenePGDPartner;
    }
    set LastGenePGDPartner(value: number) {
        this._LastGenePGDPartner = value;
    }

    get PanelFirst(): number {
        return this._PanelFirst;
    }
    set PanelFirst(value: number) {
        this._PanelFirst = value;
    }

    get PanelLast(): number {
        return this._PanelLast;
    }
    set PanelLast(value: number) {
        this._PanelLast = value;
    }

    get TotalSNPPartner(): number {
        return this._TotalSNPPartner;
    }
    set TotalSNPPartner(value: number) {
        this._TotalSNPPartner = value;
    }

    get ValidSNP(): number {
        return this._ValidSNP;
    }
    set ValidSNP(value: number) {
        this._ValidSNP = value;
    }

    get ValidSNPPartner(): number {
        return this._ValidSNPPartner;
    }
    set ValidSNPPartner(value: number) {
        this._ValidSNPPartner = value;
    }

    get KeySnp(): string {
        return this._KeySnp;
    }
    set KeySnp(value: string) {
        this._KeySnp = value;
    }

    get HerencyPatern(): string {
        return this._HerencyPatern;
    }
    set HerencyPatern(value: string) {
        this._HerencyPatern = value;
    }

    get Status():Status[] {
        return this._Status;
    }
    set Status(value:Status[]) {
        this._Status = value;
    }

    get Embryos(): Embryo[] {
        return this._Embryos;
    }
    set Embryos(value: Embryo[]) {
        this._Embryos = value;
    }

    get TotalAnalyzedSNP(): number {
        return this._TotalAnalyzedSNP;
    }
    set TotalAnalyzedSNP(value: number) {
        this._TotalAnalyzedSNP = value;
    }

    get TotalAnalyzedSNPINFO(): number {
        return this._TotalAnalyzedSNPINFO;
    }
    set TotalAnalyzedSNPINFO(value: number) {
        this._TotalAnalyzedSNPINFO = value;
    }

    get Panel(): Panel {
        return this._Panel;
    }

    toDTO(): ReportDTO {
        return {
            Name: this._Name,
            Birthdate: this._Birthdate,
            NamePartner: this._NamePartner,
            BirthdatePartner: this._BirthdatePartner,
            Gender: this._Gender,
            GenderPartner: this._GenderPartner,
            PatientId: this._PatientId,
            PatientIdPartner: this._PatientIdPartner,
            SampleType: this._SampleType,
            SampleTypePartner: this._SampleTypePartner,
            SampleDate: this._SampleDate,
            SampleDatePartner: this._SampleDatePartner,
            ReportDate: this._ReportDate,
            TotalSNP: this._TotalSNP,
            Region: this._Region,
            Chromosome: this._Chromosome,
            FirstGene: this._FirstGene,
            LastGene: this._LastGene,
            FirstGenePGD: this._FirstGenePGD,
            LastGenePGD: this._LastGenePGD,
            FirstGenePGDPartner: this._FirstGenePGDPartner,
            LastGenePGDPartner: this._LastGenePGDPartner,
            PanelFirst: this._PanelFirst,
            PanelLast: this._PanelLast,
            TotalSNPPartner: this._TotalSNPPartner,
            ValidSNP: this._ValidSNP,
            ValidSNPPartner: this._ValidSNPPartner,
            KeySnp: this._KeySnp,
            HerencyPatern: this._HerencyPatern,
            Status: this._Status,
            Embryos: this._Embryos,
            TotalAnalyzedSNP: this._TotalAnalyzedSNP,
            TotalAnalyzedSNPINFO: this._TotalAnalyzedSNPINFO,
            Panel: this._Panel
        };
    }
}

export function toModelReport(d: ReportDTO): Report {
    return new Report(d);
}